import React from 'react';
import { observer } from 'mobx-react';
import { autorun, computed, observable } from 'mobx';
import { AppUI } from '@seedlang/state';
import styled from '@emotion/styled';
import MembershipOption from 'components/membership/membership_option';
import { isPresent } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import Cookies from 'js-cookie';
import Toggle from '../toggle';
import Alert from 'components/alert';
import { Theme } from '@seedlang/constants';
import { some } from "lodash";
import { Motion, presets, spring } from "react-motion";
import { PaymentMigrationStore } from '@seedlang/stores';
import { Link } from "react-router";
import Text from "components/text";
import { SettingsBillingWrapper } from "@seedlang/hoc";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width : 760px) {
    width: 100%;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  margin-top: 30px;
`;

const CarouselWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 20px;
`;

const membershipOptionWrapperWidth = 930;

const MembershipOptionWrapper = styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent};
  gap: 15px;
  @media only screen and (max-width : 1100px) {
    width: 620px;
  }
  @media only screen and (max-width : 760px) {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    height: auto;
    width: 100%;
    margin: 0;
  }
  overflow: hidden;
  width: ${membershipOptionWrapperWidth}px;
`;

const Header = styled.div`
  margin: 40px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 280px;
  }
`;

const CarouselButton = styled.div`
  width: 25px;
  height: 25px;
  background: ${props => props.background};
  opacity: 0.6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  color: white;
  font-weight: bold;
  z-index: 1000;
  font-size: 25px;
  box-shadow: 0 0 10px 1px #d0d0d0;
  opacity: ${props => props.isActive ? '1' : '0.5'};
  cursor: ${props => props.isActive ? 'pointer' : null};
  padding: ${props => props.padding};
  &:hover {
    transform: ${props => props.isActive ? 'scale(1.1, 1.01)' : null};
  }
  &:active {
    transform: ${props => props.isActive ? 'scale(0.99)' : null};
  }
`;

const CarouselButtonWrapper = styled.div`
  height: auto;
  cursor: ${props => props.isActive ? 'pointer' : null};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
`;

const PageIndicatorWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
`;

const PageIndicator = styled.div`
  width: 12px;
  height: 12px;
  background: ${props => props.background};
  border-radius: 100px;
  opacity: ${props => props.isSelected ? '1' : '0.5'};
  cursor: ${props => props.isSelected ? 'auto' : 'pointer'};
`;

const PatreonBanner = styled.div`
  height: 30px;
  background: #AFD6E7;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  text-align: center;
  font-size: 14px;
  line-height: 28px;
`;

const Footnote = styled.div`
  font-size: 12px;
  color: ${(props) => props.color};
  line-height: 15px;
  margin: 5px auto;
  text-align: center;
  text-wrap: balance;
  max-width: ${membershipOptionWrapperWidth}px;
`;

@observer
class MembershipOptions extends React.Component {
  @observable loadedMembershipGroup = false;
  @observable carouselIndex = 0;
  @observable membershipsFilter = 1;
  @observable subscriptionLoaded = false;

  constructor(props) {
    super(props);
    if (!AppUI.userIsLoaded) {
      AppUI.loadUser();
    }
    AppUI.scrollToPageTop();
    autorun(() => {
      if (AppUI.userIsLoaded && AppUI.user.currentUserGroup?.membershipGroupId && !this.loadedMembershipGroup) {
        this.loadedMembershipGroup = true;
        this.loadMembershipGroup();
      }
      if (AppUI.userIsLoaded && AppUI.userSubscriptionStore.hasIndexData && !this.subscriptionLoaded) {
        this.subscriptionLoaded = true;
        if (isPresent(this.props.activeSubscription) && this.props.activeSubscription.membership?.numberMonths === 12) {
          this.onYearly();
        }
      }
    });
  }

  @autobind onMonthly() {
    this.membershipsFilter = 1;
  }

  @autobind onYearly() {
    this.membershipsFilter = 12;
  }

  @autobind onToggle() {
    this.membershipsFilter = this.membershipsFilter === 1 ? 12 : 1;
  }

  @computed get siteId() {
    return Cookies.get('site_id');
  }

  @computed get currency() {
    return AppUI.userIsLoaded ? AppUI.user.currency : 'usd';
  }

  @computed get currencySymbol() {
    return this.currency === 'usd' ? '$' : '€';
  }

  @autobind loadMembershipGroup() {
    if (isPresent(AppUI.user.currentUserGroup)) {
      AppUI.membershipGroupStore.getShow({ids: {membershipGroupId: AppUI.user.currentUserGroup.membershipGroupId}});
    }
  }

  @computed get filteredMemberships() {
    return isPresent(this.activeMemberships) ? this.activeMemberships.filter((item) => this.membershipsFilter === item.numberMonths && item.active) : [];
  }

  @computed get activeMemberships() {
    const membershipGroup = AppUI.user?.currentUserGroup?.membershipGroupId ? AppUI.membershipGroupStore.showData : AppUI.user?.site?.membershipGroup;
    return isPresent(membershipGroup) ? membershipGroup.memberships.filter((item) => item.active) : [];
  }

  @autobind onClick(membership) {
    if (!AppUI.siteIsDefault && AppUI.user.hasPermanentGroupMembership) {
      return;
    }
    AppUI.set('currentlyViewedMembership', membership);

    if (this.props.mode === 'payment_migration' || AppUI.isPaymentMigrationStartable) {
      PaymentMigrationStore.updateMembership(membership.id, (resp) => {
        AppUI.loadUser();
        AppUI.routeStore.routeToNamed('onboarding.migration');
      });
      return;
    }
    const routeTo = window.location.href && window.location.href.match("settings") ? 'settings.memberships.create' : 'memberships.create';
    AppUI.routeStore.routeToNamed(routeTo, {membershipId: membership.id})
  }

  @computed get topSectionHeight() {
    let height = 160;

    if (some(this.filteredMemberships.map(item => isPresent(item.imageUrl)))) {
      height += 170;
      if (some(this.activeMemberships.map(item => isPresent(item.discount) && item.discount > 0))) {
        height += 31;
      }
    }

    height += this.notesHeight - 20; // 20px is the default for a single line

    return height;
  }

  @computed get notesHeight() {
    const lineHeight = 15;
    const lineCount = AppUI.siteIsDefault ? 1 : 3; // "€300 billed annually.", "Minimum contract term:", "1 year, then cancelable monthly."
    const paragraphCount = AppUI.siteIsDefault ? 1 : 2;
    const paragraphMargin = 5;
    return lineHeight * lineCount + paragraphMargin * (paragraphCount - 1);
  }

  @autobind onToggleCarousselButtonLeft() {
    if (this.carouselIndex > 0) {
      this.carouselIndex = Math.max(this.carouselIndex - this.numVisibleTiers, 0);
    }
  }

  @autobind onToggleCarousselButtonRight() {
    if (this.carouselIndex < this.filteredMemberships.length) {
      this.carouselIndex = Math.min(this.carouselIndex + this.numVisibleTiers, this.filteredMemberships.length-this.numVisibleTiers);
    }
  }

  @autobind goToPage(index) {
    if (index !== this.currentPage) {
      this.carouselIndex = Math.min(index*this.numVisibleTiers, this.filteredMemberships.length-this.numVisibleTiers);
    }
  }

  @computed get numberPages() {
    return Math.ceil(this.filteredMemberships.length/this.numVisibleTiers);
  }

  @computed get currentPage() {
    return Math.ceil(this.carouselIndex/this.numVisibleTiers);
  }

  @computed get numVisibleTiers() {
    if (AppUI.layout.viewportWidth > 1100) {
      return 3;
    } if (AppUI.layout.viewportWidth < 760) {
      return 1;
    } return 2;
  }


  render() {
    return (
      <Wrapper>
        {
          !AppUI.user.anyAdmin && !AppUI.siteIsDefault && !AppUI.user.isMember && AppUI.site.allowPatreonSignIn &&
            <PatreonBanner>
              Already a member on Patreon? <Link to={{name: 'onboarding.patreon'}} className="underline">Sign In here</Link>
            </PatreonBanner>
        }
        {
          !AppUI.siteIsDefault && AppUI.user.hasPermanentGroupMembership &&
            <Alert
              textAlign="center"
              margin="20px 0 20px 0"
              background={Theme.green}
            >
              You have a free membership and will not be able to purchase a membership. Please contact us if you think there is an error.
            </Alert>
        }
        {
          AppUI.isPaymentMigrationStartable && this.props.mode !== 'payment_migration' &&
            <Alert
              textAlign="center"
              margin="20px 0 20px 0"
              background={Theme.green}
            >
              You are currently subscribed via Patreon. If you wish to change your membership, you will then be asked to migrate your payment to our platform.
            </Alert>
        }
        {
          this.props.mode === 'payment_migration' && AppUI.isPaymentMigrationStartable &&
          <Alert
              textAlign="center"
              margin="20px 0 20px 0"
              background={Theme.green}
            >
              You are currently migrating your payment. You can change your membership tier by selecting it below.
            </Alert>
        }
        {
          AppUI.user.site?.showCustomMembershipHtml &&
            <Header>
              {
                AppUI.user.site && isPresent(AppUI.user.site.beforeMembershipsListingHtml) &&
                  <div
                    dangerouslySetInnerHTML={{ __html: AppUI.user.site.beforeMembershipsListingHtml}}
                  />
              }
            </Header>
        }
        <InnerWrapper>
          <Text heading={2} margin="0 auto 10px auto">Choose Your Membership</Text>
          <Toggle
            labels={['Monthly', 'Yearly']}
            selected={this.membershipsFilter === 12}
            onSelect={this.onYearly}
            onDeselect={this.onMonthly}
            onToggle={this.onToggle}
            highlightColor={AppUI.site.accentColor}
          />
          <CarouselWrapper>
            {
              this.filteredMemberships && this.filteredMemberships.length > this.numVisibleTiers && AppUI.layout.isDesktop &&
                <CarouselButtonWrapper
                  onClick={this.onToggleCarousselButtonLeft}
                  isActive={this.filteredMemberships.length > this.numVisibleTiers && this.carouselIndex > 0}
                >
                  <CarouselButton
                    padding="0 7px 0 5px"
                    background={AppUI.site.accentColor}
                    isActive={this.filteredMemberships.length > this.numVisibleTiers && this.carouselIndex > 0}
                  >
                    <i className='fa fa-angle-left'/>
                  </CarouselButton>
                </CarouselButtonWrapper>
            }
            <MembershipOptionWrapper
              justifyContent={this.filteredMemberships.length > this.numVisibleTiers ? 'flex-start' : 'center'}
            >
              {
                this.filteredMemberships && this.filteredMemberships.map((item, index) => {
                  return (
                    <Motion
                      key={item.id}
                      style={{
                        x: spring(-315*this.carouselIndex, presets.stiff),
                      }}
                    >
                      {
                        ({opacity, x}) =>
                          <MembershipOption
                            style={{
                              WebkitTransform: `translate(${x}, 0)`,
                              transform: `translate(${x}px, 0)`,
                            }}
                            lightBackground
                            onlyButtonIsClickable
                            hoverTransform="scale(1.03)"
                            clickTransform="scale(0.97)"
                            user={AppUI.user}
                            currency={this.currency}
                            currencySymbol={this.currencySymbol}
                            membership={item}
                            signedOut={AppUI.user.signedOut}
                            onClick={this.onClick}
                            borderRadius="5px"
                            background='#FFF'
                            accentColor={AppUI.site.accentColor || "#1f4d6b"}
                            hideBorder
                            hideIcon
                            width={this.numVisibleTiers === 1 ? '90%' : "300px"}
                            boxShadow="0 0 10px 1px #d0d0d0"
                            topSectionHeight={this.topSectionHeight}
                            notesHeight={this.notesHeight}
                            mode={this.props.mode}
                            memberships={this.activeMemberships}
                            toggleYearly={this.onToggle}
                            activeSubscription={this.props.activeSubscription}
                            paymentMigration={AppUI.isPaymentMigrationStartable || AppUI.isPaymentMigrationInProgress ? AppUI.user.paymentMigration : null}
                          />
                      }
                    </Motion>
                  )
                })
              }
            </MembershipOptionWrapper>
            {
              this.filteredMemberships && this.filteredMemberships.length > this.numVisibleTiers && AppUI.layout.isDesktop &&
                <CarouselButtonWrapper
                  onClick={this.onToggleCarousselButtonRight}
                  isActive={this.filteredMemberships.length > this.numVisibleTiers && this.carouselIndex > 0}
                >
                  <CarouselButton
                    padding="0 5px 0 7px"
                    background={AppUI.site.accentColor}
                    isActive={this.filteredMemberships.length > this.numVisibleTiers && this.carouselIndex < this.filteredMemberships.length - this.numVisibleTiers}
                  >
                    <i className='fa fa-angle-right'/>
                  </CarouselButton>
                </CarouselButtonWrapper>
            }
          </CarouselWrapper>
          {
            this.filteredMemberships?.length > this.numVisibleTiers && this.numVisibleTiers > 1 &&
              <PageIndicatorWrapper>
                {
                  [...Array(this.numberPages)].map((item, i) => {
                    return (
                      <PageIndicator
                        background={AppUI.site.accentColor}
                        key={i}
                        isSelected={i === this.currentPage}
                        onClick={() => this.goToPage(i)}
                      />
                    )
                  })
                }
              </PageIndicatorWrapper>
          }
          {
            AppUI.user.site?.showCustomMembershipHtml &&
              <Header>
                {
                  AppUI.user.site && isPresent(AppUI.user.site.afterMembershipsListingHtml) &&
                    <div
                      dangerouslySetInnerHTML={{ __html: AppUI.user.site.afterMembershipsListingHtml}}
                    />
                }
              </Header>
          }

          {
            !AppUI.siteIsDefault && (
              <Footnote>
                Our membership platform is hosted by Seedlang, Inc. By signing up, you create a free user account with Seedlang (<a href="https://seedlang.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a>), which can also be used for any Easy Languages membership. If you choose a paid membership on this page, you'll enter into a paid subscription agreement with Easy Languages GmbH. You can also use an existing Easy Languages or Seedlang account.
              </Footnote>
            )
          }
        </InnerWrapper>
      </Wrapper>
    );
  }
}

export default SettingsBillingWrapper(MembershipOptions);
