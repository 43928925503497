import React from 'react';
import { observer } from 'mobx-react';
import { CustomTabStore, MembershipTypeCustomTabStore, MembershipTypeStore, SiteStore } from '@seedlang/stores';
import { computed } from 'mobx';
import autobind from 'autobind-decorator';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import Cookies from 'js-cookie';
import { find, noop } from 'lodash';
import InPlaceImageUpload from "components/form/in_place_image_upload";
import Text from 'components/text';
import IconInPlaceSelect from "pages/icon_in_place_select";

@observer
class CustomTabEdit extends React.Component {
  static defaultProps = {
    whiteLabeled: false
  }

  componentDidMount() {
    if (!this.props.whiteLabeled) {
      SiteStore.getIndex();
    }
    this.getMembershipTypes();
    this.getCustomTab();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.routeParams.customTabId !== prevProps.routeParams.customTabId) {
      this.getCustomTab();
    }
  }

  componentWillUnmount() {
    CustomTabStore.clearShowData();
  }

  @computed get customTab() {
    return CustomTabStore.showData;
  }

  @computed get filteredMembershipTypes() {
    return MembershipTypeStore.indexData.filter(item => item.siteId === Cookies.get('site_id'));
  }

  @autobind getCustomTab() {
    CustomTabStore.getShow({ ids: { customTabId: this.props.routeParams.customTabId }});
  }

  @autobind afterGetShow(resp) {
    CustomTabStore.setShowData(resp);
  }

  @autobind getMembershipTypes() {
    MembershipTypeStore.getIndex();
  }

  @autobind getMembershipTypeCustomTab(membershipTypeId) {
    return find(this.customTab.membershipTypeCustomTabs, ['membershipTypeId', membershipTypeId]);
  }

  @autobind onChange(membershipTypeCustomTab, membershipTypeId, value) {
    if (value) {
      MembershipTypeCustomTabStore.create({ data: { custom_tab_id: this.customTab.id, membership_type_id: membershipTypeId } }, noop);
    } else {
      MembershipTypeCustomTabStore.destroy({ ids: { membershipTypeCustomTabId: membershipTypeCustomTab.id } }, noop);
    }
    this.getCustomTab();
  }

  render() {
    if (CustomTabStore.showData) {
      return (
        <div className="custom-tabs-edit">
          <div className="row">
            <div className="col-xs-4">
              <fieldset>
                <legend>Name</legend>
                <InPlaceText
                  id={this.customTab.id}
                  defaultValue={this.customTab.title}
                  model="custom_tabs"
                  field="title"
                />
              </fieldset>
            </div>
            <div className="col-xs-4">
              <fieldset>
                <legend>Published</legend>
                <InPlaceCheckbox
                  icon="check"
                  value={this.customTab.published}
                  id={this.customTab.id}
                  field='published'
                  model="custom_tabs"
                />
              </fieldset>
            </div>
            <div className="col-xs-4">
              <fieldset style={{display: 'flex', alignItems: 'center'}}>
                <legend>Icon</legend>
                <IconInPlaceSelect
                  includeBlank={false}
                  id={this.customTab.id}
                  value={this.customTab.icon}
                  model="custom_tabs"
                  field="icon"
                  options={[
                    ['question-circle', 'Question'],
                    ['link', 'Link'],
                    ['download', 'Download'],
                    ['play-circle', 'Play'],
                    ['puzzle-piece', 'Puzzle'],
                    ['lightbulb-o', 'Lightbulb'],
                    ['rocket', 'Rocket'],
                    ['globe', 'Globe'],
                  ]}
                  afterChange={this.getCustomTab}
                />
              </fieldset>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6">
              <fieldset>
                <legend>Membership Types</legend>
                {
                  this.filteredMembershipTypes.map((item) => {
                    return (
                      <div
                        key={item.id}
                      >
                        <InPlaceCheckbox
                          icon="check"
                          value={Boolean(this.getMembershipTypeCustomTab(item.id))}
                          model="membership_type_custom_tabs"
                          onChange={(value) => this.onChange(this.getMembershipTypeCustomTab(item.id), item.id, value)}
                        >
                          {item.name}
                        </InPlaceCheckbox>
                      </div>
                    );
                  })
                }
              </fieldset>
            </div>
            <div className="col-xs-6">
              <fieldset style={{width: '100%'}}>
                <legend>Other Filters</legend>
                <div>
                  <InPlaceCheckbox
                    icon="check"
                    id={CustomTabStore.showData.id}
                    value={CustomTabStore.showData?.showToNonMembers}
                    field='show_to_non_members'
                    model="custom_tabs"
                  >
                    Show to non members
                  </InPlaceCheckbox>
                </div>
                <div>
                  <InPlaceCheckbox
                    icon="check"
                    id={CustomTabStore.showData.id}
                    value={CustomTabStore.showData?.blockIfNotMigrated}
                    field='block_if_not_migrated'
                    model="custom_tabs"
                  >
                    Block if User Needs to Migrate
                  </InPlaceCheckbox>
                </div>
                <div>
                  <InPlaceCheckbox
                    icon="check"
                    id={CustomTabStore.showData.id}
                    value={CustomTabStore.showData?.showOnVideoWorksheets}
                    field='show_on_video_worksheets'
                    model="custom_tabs"
                  >
                    Show on Video Posts
                  </InPlaceCheckbox>
                </div>
                <div>
                  <InPlaceCheckbox
                    icon="check"
                    id={CustomTabStore.showData.id}
                    value={CustomTabStore.showData?.showOnPodcastWorksheets}
                    field='show_on_podcast_worksheets'
                    model="custom_tabs"
                  >
                    Show on Podcast Posts
                  </InPlaceCheckbox>
                </div>
              </fieldset>
            </div>
            <div className="col-xs-12">
              <fieldset>
                <legend>Text</legend>
                <InPlaceText
                  wrapperClassName="word-info"
                  richText
                  defaultValue={this.customTab.content}
                  model="custom_tabs"
                  field="content"
                  inputType="textarea"
                  id={this.customTab.id}
                />
              </fieldset>
            </div>
            <div className="col-xs-12">
              <fieldset>
                <legend>Image</legend>
                <Text fontSize='15px'>This image will be displayed with full width under the text.</Text>
                <InPlaceImageUpload
                  image={this.customTab.image}
                  model="images"
                  onChange={this.getCustomTab}
                  querystring={{
                    imageable_id: this.customTab.id,
                    imageable_type: 'CustomTab',
                  }}
                />
                <InPlaceText
                  show
                  id={this.customTab.id}
                  defaultValue={this.customTab.imageLinkUrl}
                  model="custom_tabs"
                  field="image_link_url"
                  label="Image link url"
                />
              </fieldset>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default CustomTabEdit;

