import styled from '@emotion/styled';
import Button from 'components/button/button';
import Text from 'components/text';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

const ConfirmationMessage = styled.div`
  padding: 0 20px;
  margin: 20px 0 0 0;
`;

@observer
class CheckoutWhitelabelConfirmationStep extends React.Component {

  constructor(props) {
    super(props);
  }

  @computed get subscriptionChangeOption() {
    return this.props.subscriptionChangeOption;
  }

  render() {
    return (
      <>
        {
          this.subscriptionChangeOption.earliestChangePossibleAt && this.subscriptionChangeOption.earliestChangePossibleAt.isFuture ? (
            <ConfirmationMessage>
              <Text>
                Your subscription will change on {this.subscriptionChangeOption.earliestChangePossibleAt.formattedDateWithYear}, at the end of your current billing cycle.
              </Text>
              <Text margin="20px 0 0 0">
                You’ll be redirected to securely update your payment information.
              </Text>
              <Text margin="20px 0 0 0">
                Your card will be charged on {this.subscriptionChangeOption.earliestChangePossibleAt.formattedDateWithYear} with the new subscription rate.
              </Text>
            </ConfirmationMessage>
          ) : this.subscriptionChangeOption.isProratedRefundAvailable ? (
            <ConfirmationMessage>
              <Text>
                Complete your payment on the next page to start your new subscription right away.
              </Text>
              <Text margin="20px 0 0 0">
                We'll refund the remaining balance of your current subscription.
              </Text>
            </ConfirmationMessage>
          ) : (
            null
          )
        }
        <Button
          margin="20px 0 0 0"
          onClick={this.props.onConfirm}
        >
          Continue
        </Button>
      </>
    )
  }
}

export default CheckoutWhitelabelConfirmationStep;
