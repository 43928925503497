import React from 'react';
import { observer } from 'mobx-react';
import { autorun, computed, observable } from 'mobx';
import styled from '@emotion/styled';
import Text from 'components/text';
import { AppUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import { isPresent } from '@seedlang/utils';
import { each, intersection, startCase } from 'lodash';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  width: ${props => props.width};
  height: fit-content;
  align-items: center;
`;

const ScrollWrapper = styled.div`
  display: flex;
  height: fit-content;
  flex-wrap: wrap;
  padding: 2px 0;
`;

const Tag = styled.div`
  background: #FFF;
  padding: 5px;
  padding-right: ${props => props.paddingRight};
  border-radius: 5px;
  margin: 3px;
  position: relative;
  border: 1px solid #CCC;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: max-content;
`;

const TimesIconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
  i {
    font-size: 12px;
  }
`;

@observer
class WorksheetFilterTags extends React.Component {

  @computed get worksheetTagIdsAry() {
    return AppUI.user.currentUserGroup.worksheetTagIds.split(",");
  }

  @autobind tagText(tagId) {
    let text = '';
    each(this.props.tagCategories, ((tagCategory) => {
      let tag = tagCategory.tags.find(item => item.id === tagId);
      if (isPresent(tag)) {
        text = `${tagCategory.name}: ${tag.name}`;
      }
    }))
    return text;
  }

  @autobind onRemoveTag(tagCategory) {
    let ary = this.worksheetTagIdsAry;
    each(tagCategory.tags, (tag) => {
      ary = ary.filter(item => item !== tag.id);
    })
    const stringValue = isPresent(ary) ? ary.join(",") : null;
    AppUI.user.currentUserGroup.set('worksheetTagIds', stringValue);
    AppUI.userGroupStore.update({ids: {userGroupId: AppUI.user.currentUserGroup.id}, data: {worksheetTagIds: stringValue}}, this.props.onUpdateFilter)
  }

  @autobind onRemoveProgressTag() {
    AppUI.user.currentUserGroup.set('worksheetProgressTags', '');
    AppUI.userGroupStore.update({ids: {userGroupId: AppUI.user.currentUserGroup.id}, data: {worksheetProgressTags: ''}}, this.props.onUpdateFilter)
  }

  render () {
    return (
      <Wrapper
        width={this.props.width}
      >
        <ScrollWrapper>
          {
            isPresent(AppUI.user.currentUserGroup.worksheetProgressTags) && this.props.showProgressTag &&
              <Tag
                paddingRight="20px"
              >
                <Text
                  color="#333"
                  bold
                  fontSize={12}
                  lineHeight={15}
                >
                  Progress: {AppUI.user.currentUserGroup.worksheetProgressTags?.split(',').map(item => startCase(item.replace('-', ' '))).join(", ")}
                </Text>
                <TimesIconWrapper
                  onClick={this.onRemoveProgressTag}
                >
                  <i className='fa fa-times' />
                </TimesIconWrapper>
              </Tag>
          }
          {
            this.props.tagCategories?.map(tagCategory => {
              if (isPresent(intersection(AppUI.user.currentUserGroup.worksheetTagIds?.split(","), tagCategory.tags.map(item => item.id)))) {
                return (
                  <Tag
                    key={tagCategory.id}
                    paddingRight="20px"
                  >
                    <Text
                      color="#333"
                      bold
                      fontSize={12}
                      lineHeight={15}
                    >
                      {tagCategory.name}: {tagCategory.tags.filter(item => this.worksheetTagIdsAry.includes(item.id)).map(item => item.name).join(", ")}
                    </Text>
                    <TimesIconWrapper
                      onClick={() => this.onRemoveTag(tagCategory)}
                    >
                      <i className='fa fa-times' />
                    </TimesIconWrapper>
                  </Tag>
                )
              }  else {
                return null;
              }
            })
          }
        </ScrollWrapper>
      </Wrapper>
    );
  }
}

export default WorksheetFilterTags;
