import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Link } from 'react-router';
import { GridItemStore, GridItemWorksheetStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import InPlaceText from 'components/form/in_place_text';
import WorksheetSearch from 'components/worksheet_search';
import DeleteButton from 'components/button/delete_button';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`
  table {
    margin-top: 20px;
  }
`;

@observer
class GridItemEdit extends React.Component {

  constructor(props) {
    super(props);
    this.getGridItem();
    AppUI.worksheetStore.clearIndexData();
  }

  @autobind getGridItem() {
    GridItemStore.getShow({ids: {gridItemId: this.props.params.gridItemId}});
  }

  @autobind onAddWorksheet(params) {
    GridItemWorksheetStore.create({data: {worksheetId: params.id, gridItemId: this.props.params.gridItemId}}, this.getGridItem);
  }

  @autobind onRemoveWorksheet(id) {
    GridItemWorksheetStore.destroy({ids: {gridItemWorksheetId: id}}, this.getGridItem);
  }

  render () {
    return (
      <Wrapper>
        <div className='breadcrumbs' style={{marginBottom: 20}}>
          <Link
            to={{name: 'creator.grid_items.index', params: {groupId: this.props.params.groupId}}}
          >
            View Grid Items
          </Link>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Name</legend>
              <InPlaceText
                field="name"
                defaultValue={GridItemStore.showDataField('name')}
                model="grid_items"
                id={GridItemStore.showDataField('id')}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Posts</legend>
              <WorksheetSearch
                onSubmit={this.onAddWorksheet}
              />
              {
                GridItemStore.hasShowData &&
                  <table className="table-wrapper admin-table">
                    <thead>
                      <tr>
                        <th>Position</th>
                        <th>Name</th>
                        <th width="40"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        GridItemStore.showData.gridItemWorksheets.map((item) => {
                          return (
                            <tr key={item.id}>
                              <td style={{width: "100px"}}>
                                <div className="text">
                                  <InPlaceText
                                    field="position"
                                    defaultValue={item.position}
                                    model="grid_item_worksheets"
                                    id={item.id}
                                    afterChange={this.getGridItem}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="text">
                                  {item.worksheet.name}
                                </div>
                              </td>
                              <td>
                                <DeleteButton
                                  onConfirm={() => this.onRemoveWorksheet(item.id)}
                                  message="Remove this worksheet?"
                                  className="fa-block right"
                                />
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
              }
            </fieldset>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(GridItemEdit);
