import React from 'react';
import { observer } from 'mobx-react';
import { autorun, observable } from 'mobx';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import { GridItemWorksheetStore, WorksheetStore } from '@seedlang/stores';
import ExerciseIndex from 'pages/creator/exercises/exercise_index';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import InPlaceFileUpload from 'components/form/in_place_file_upload';
import InPlaceImageUpload from 'components/form/in_place_image_upload';
import styled from '@emotion/styled';
import GridItemSearch from 'components/grid_item_search';
import DeleteButton from 'components/button/delete_button';
import { AppUI } from '@seedlang/state';
import PodcastEpisodeSearch from 'components/podcast_episode_search';
import { isBlank, isPresent } from '@seedlang/utils';
import TagEdit from 'pages/creator/tags/tag_edit';
import DownloadLinkEdit from 'pages/creator/download_links/download_link_edit';
import Modal from 'components/modal';
import ExerciseCreate from "pages/creator/exercises/exercise_create";
import ReactTooltip from 'react-tooltip';
import Text from 'components/text';
import MembershipTypeSelect from "pages/creator/membership_types/membership_type_select";
import Tags from "components/worksheet/tags";
import InfoTooltip from "components/info_tooltip";
import InPlaceDatePicker from "components/form/in_place_date_picker";
import CheckIfTrue from "components/check_if_true";
import Alert from "components/alert";
import { Theme } from "@seedlang/constants/src";

const Row = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: 10px;
  }
`;

const TableText = styled.div`
  text-transform: uppercase;
  display: flex;
`;

const SubText = styled.div`
  font-style: italic;
  font-size: 12px;
`;

const Wrapper = styled.div`
  input, textarea {
    border: 0px solid #CCC!important;
  }
  table {
    border: 0px;
    td {
      padding: 5px;
    }
    tr {
      background: transparent!important;
    }
  }
`;

const PodcastEpisode = styled.div`
  display: flex;
  margin-top: 5px;
  padding: 5px;
  background: #FFF;
  font-size: 16px;
`;

const Title = styled.div`
  flex: 1;
`;

const InvalidIconWrapper = styled.div`
  text-transform: none;
  margin: ${props => props.margin};
`;

const MembershipTypesRow = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  cursor: pointer;
  .fa {
    color: ${props => props.active ? '#000' : '#CCC'};
    margin: 0 8px 0 2px;
    font-size: 18px;
  }
`;

const NameDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DatePickerRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const ImageWrapper = styled.div`
  position: relative;
  display: inline-block;
  .info-tooltip {
    position: absolute;
    left: 0;
    background: green;
    color: white;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 24px;
    border-radius: 50%;
    font-size: 14px;
  }
  .fa-times {
    position: absolute;
    right: 0;
    background: red;
    color: white;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 24px;
    border-radius: 50%;
    font-size: 14px;
    cursor: pointer;
  }
  img {
    margin-top: 10px;
    max-width: 180px;
  }
`;

const Image = styled.div`
  background-size: cover;
  background-position: center center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-image: ${(props) => props.backgroundImage};
`;

@observer
class WorksheetEdit extends React.Component {
  @observable exercises = [];
  @observable editTags = false;
  @observable editMemberships= false;
  @observable editDownloadLinks = false;
  @observable showPublicationAlert = false;
  @observable showNotificationAlert = false;
  @observable pdfGenerating = false;
  @observable askForPdfGenerationConfirm = false;
  @observable showPdfGeneratedConfirmation = false;
  @observable showSlowPdfGenerationWarning = false;
  @observable worksheetDownloadLinkType;
  @observable worksheetDownloadLink;

  constructor(props) {
    super(props);
    this.getWorksheet();
    AppUI.downloadLinkTypeStore.getIndex();
    let loaded = false;

    autorun(() => {
      if (AppUI.downloadLinkTypeStore.hasIndexData && WorksheetStore.hasShowData && !loaded) {
        loaded = true;
        this.worksheetDownloadLinkType = AppUI.downloadLinkTypeStore.indexData.find(item => item.generatedPdfs);
        if (isPresent(this.worksheetDownloadLinkType)) {
          this.worksheetDownloadLink = WorksheetStore.showData.downloadLinks.find(item => item.downloadLinkTypeId === this.worksheetDownloadLinkType.id);
        }
      }
    });
  }

  @autobind getWorksheet() {
    WorksheetStore.getShow({ids: {worksheetId: this.props.params.worksheetId}}, this.afterGetShow);
  }

  @autobind afterGetShow(resp) {
    WorksheetStore.setShowData(resp);
    this.exercises = WorksheetStore.showData.exercises;
    if (this.pdfGenerating) {
      this.pdfGenerating = false;
      this.worksheetDownloadLink = null;
      if (isPresent(this.worksheetDownloadLink)) {
        this.showPdfGeneratedConfirmation = true;
      } else {
        this.showSlowPdfGenerationWarning = true;
      }
    }
  }

  @autobind onAddGridItem(gridItem) {
    GridItemWorksheetStore.create({data: {gridItemId: gridItem.id, worksheetId: this.props.params.worksheetId}}, this.getWorksheet);
  }

  @autobind onRemoveGridItem(id) {
    WorksheetStore.removeGridItem({data: {grid_item_id: id, worksheet_id: this.props.params.worksheetId}}, this.getWorksheet);
  }

  @autobind addPodcastEpisode(podcastEpisode) {
    WorksheetStore.update({data: {podcastEpisodeId: podcastEpisode.id}, ids: {worksheetId: this.props.params.worksheetId}}, this.getWorksheet);
  }

  @autobind clearPodcastEpisode() {
    WorksheetStore.update({data: {podcastEpisodeId: null}, ids: {worksheetId: this.props.params.worksheetId}}, this.getWorksheet);
  }

  republish() {
    WorksheetStore.update({data: {published: true}, ids: {worksheetId: this.props.params.worksheetId}});
  }

  gotoPdfLink() {
    const url = `/worksheet_exports/${WorksheetStore.showData.id}.pdf?timestamp=${+ new Date()}`;
    const win = window.open(url, '_blank');
    win.focus();
  }

  @autobind generatePdfLink() {
    this.pdfGenerating = true;
    this.askForPdfGenerationConfirm = false;
    if (isPresent(this.worksheetDownloadLink)) {
      this.worksheetDownloadLink.set('text', '');
    }

    const successCallback = ((resp) => {
      this.getWorksheet();
    });

    const failureCallback = ((resp) => {
      this.pdfGenerating = false;
    });

    WorksheetStore.generatePdfLink({
      ids: {worksheetId: this.props.params.worksheetId},
      failureCallback
    }, successCallback);

    setTimeout(() => {
      if (this.pdfGenerating === true) {
        this.showSlowPdfGenerationWarning = true;
      }
    }, 5000);
  }

  @autobind onClickGeneratePdf() {
    if (isPresent(this.worksheetDownloadLink?.text) && isPresent(this.worksheetDownloadLinkType)) {
      this.askForPdfGenerationConfirm = true;
    } else {
      this.generatePdfLink();
    }
  }

  @autobind afterUpdate(resp) {
    if (isPresent(resp) && resp.wasPublished) {
      this.showPublicationAlert = true;
      this.showNotificationAlert = resp.wasNotified;
    } else {
      this.showPublicationAlert = false;
      this.showNotificationAlert = false;
    }
  }

  @autobind hideYoutubeThumbnail() {
    WorksheetStore.update({data: {hideYoutubeThumbnail: true}, ids: {worksheetId: this.props.params.worksheetId}}, this.getWorksheet);
  }

  render() {
    return (
      <Wrapper>
        {
          this.editTags &&
            <Modal
              onCloseModal={() => this.editTags = false}
              height="550px"
              iconFill="#000"
            >
              <TagEdit
                groupId={this.props.params.groupId}
                worksheet={WorksheetStore.showData}
                onChange={this.getWorksheet}
              />
            </Modal>
        }
        {
          this.editMemberships &&
            <Modal
              onCloseModal={() => this.editMemberships = false}
              height="300px"
              width="450px"
              top="200px"
              iconFill="#000"
            >
              <MembershipTypeSelect
                groupId={this.props.params.groupId}
                worksheet={WorksheetStore.showData}
                onChange={this.getWorksheet}
              />
            </Modal>
        }
        {
          WorksheetStore.hasShowData && this.editDownloadLinks &&
            <Modal
              onCloseModal={() => this.editDownloadLinks = false}
              height="550px"
              iconFill="#000"
            >
              <DownloadLinkEdit
                groupId={this.props.params.groupId}
                worksheet={WorksheetStore.showData}
                onChange={this.getWorksheet}
              />
            </Modal>
        }
        <div className='worksheet-edit'>
          <div className='breadcrumbs'>
            <div>
              <Link
                to={{name: "creator.groups.worksheets.index", params: {groupId: this.props.params.groupId}}}
              >
                Posts
              </Link>
            </div>
            <i className='fa fa-angle-double-right' />
            <div className='current'>
              {WorksheetStore.showData.name}
            </div>
          </div>
          {
            this.showPublicationAlert &&
              <Alert background={Theme.green} margin='5px 0'>{`The worksheet was just published${this.showNotificationAlert ? ' and users were notified' : ''}`}</Alert>
          }
          <div className='row'>
            <div className='col-xs-5'>
              <fieldset style={{height: '100%'}}>
                <legend>Name</legend>
                <InPlaceText
                  submitOnEnter
                  defaultValue={WorksheetStore.showData.name}
                  model="worksheets"
                  field="name"
                  id={WorksheetStore.showData.id}
                  updateCallback={this.afterUpdate}
                />
                <NameDetails>
                  <Tags
                    withEpisodeCode
                    worksheet={WorksheetStore.showData}
                  />
                  <InfoTooltip>
                    <div>Follow this format for video episode numbers:</div>
                    <div>"(SEG32)" at the end of the name</div>
                  </InfoTooltip>
                </NameDetails>
              </fieldset>
            </div>
            <div className='col-xs-4'>
              <fieldset style={{height: '100%'}}>
                <legend>Settings</legend>
                <div
                  style={{display: 'flex', justifyContent: 'space-between'}}
                >
                  <span
                    data-tip
                    data-for='published'
                  >
                    <CheckIfTrue
                      value={WorksheetStore.showData.published}
                      icon="check"
                      label="Published"
                      iconFontSize='18px'
                    />
                    <ReactTooltip
                      type="dark"
                      effect="solid"
                      id='published'
                      class="custom-tooltip"
                    >
                      {
                        !WorksheetStore.showData.published && WorksheetStore.showData.publishedAt?.isFuture &&
                          <div>{`This worksheet will be published automatically ${WorksheetStore.showData.publishedAt.relativeTime}.`}</div>
                      }
                      {
                        !WorksheetStore.showData.published && isBlank(WorksheetStore.showData.publishedAt) &&
                          <div>Set a publication date to publish this worksheet.</div>
                      }
                      {
                        WorksheetStore.showData.published &&
                          <div>To unpublish, delete the publication date or set it in the future.</div>
                      }
                    </ReactTooltip>
                  </span>
                  <div>
                    <InPlaceCheckbox
                      value={WorksheetStore.showData.pinned}
                      field="pinned"
                      model="worksheets"
                      id={WorksheetStore.showData.id}
                      afterChange={this.getWorksheet}
                      icon="check"
                    >
                      Pinned
                    </InPlaceCheckbox>
                  </div>
                </div>
                <InPlaceCheckbox
                  value={WorksheetStore.showData.skipNotification}
                  field="skip_notification"
                  model="worksheets"
                  id={WorksheetStore.showData.id}
                  afterChange={this.getWorksheet}
                  icon="check"
                  disabled={WorksheetStore.showData.published}
                >
                  Do Not Notify Members on Publication
                </InPlaceCheckbox>
                <div>
                  <InPlaceCheckbox
                    value={WorksheetStore.showData.accessWithoutMembership}
                    field="access_without_membership"
                    model="worksheets"
                    id={WorksheetStore.showData.id}
                    afterChange={this.getWorksheet}
                    icon="check"
                  >
                    Can Access without Membership
                  </InPlaceCheckbox>
                </div>
                <MembershipTypesRow
                  active={isPresent(WorksheetStore.showData.worksheetMembershipTypes)}
                  onClick={() => this.editMemberships = true}
                >
                  <i className={`fa fa-${isPresent(WorksheetStore.showData.worksheetMembershipTypes) ? 'lock' : 'unlock'}`} />
                  {
                    isBlank(WorksheetStore.showData.worksheetMembershipTypes) &&
                      "Accessible with all membership tiers"
                  }
                  {
                    isPresent(WorksheetStore.showData.worksheetMembershipTypes) &&
                      `Accessible with ${WorksheetStore.showData.membershipTypeNames}`
                  }
                </MembershipTypesRow>
                {
                  WorksheetStore.hasShowData &&
                  <Link
                    to={{name: "worksheets.show", params: {worksheetId: WorksheetStore.showData.id}}}
                    className='underline'
                    style={{fontSize: 12}}
                  >
                    Go to worksheet
                  </Link>
                }
              </fieldset>
            </div>
            <div className='col-xs-3'>
              <fieldset style={{height: '100%'}}>
                <legend style={{marginBottom: '-15px'}}>Publication Date</legend>
                <DatePickerRow>
                  <InPlaceDatePicker
                    withTime
                    label='Post publication date'
                    model="worksheets"
                    field="published_at"
                    id={WorksheetStore.showData.id}
                    value={WorksheetStore.showData.publishedAt}
                    afterChange={this.getWorksheet}
                    timeIntervals={60}
                    updateCallback={this.afterUpdate}
                  />
                  <InfoTooltip position='left'>Select the publication time based on your local time</InfoTooltip>
                  {
                    !WorksheetStore.showData.isValid &&
                      <InvalidIconWrapper
                        data-tip
                        data-for='valid-publish'
                      >
                        <i className='fa fa-warning' />
                        <ReactTooltip
                          type="dark"
                          effect="solid"
                          id='valid-publish'
                          class="custom-tooltip"
                          place="left"
                        >
                          <div>Look at the warning icons on the page</div>
                          <div>to make the post valid.</div>
                        </ReactTooltip>
                      </InvalidIconWrapper>
                  }
                  {
                    WorksheetStore.showData.isValid && WorksheetStore.showData.hasUnpublishedVocab &&
                      <InvalidIconWrapper
                        data-tip
                        data-for='valid-publish-vocab'
                      >
                        <i className='fa fa-warning' />
                        <ReactTooltip
                          type="dark"
                          effect="solid"
                          id='valid-publish-vocab'
                          class="custom-tooltip"
                          place="left"
                        >
                          <div>This worksheet is valid but</div>
                          <div>has unpublished vocab ↓</div>
                        </ReactTooltip>
                      </InvalidIconWrapper>
                  }
                </DatePickerRow>
                <DatePickerRow>
                  <InPlaceDatePicker
                    label='Content publication date'
                    model="worksheets"
                    field="content_published_at"
                    id={WorksheetStore.showData.id}
                    value={WorksheetStore.showData.contentPublishedAt}
                    afterChange={this.getWorksheet}
                    updateCallback={this.afterUpdate}
                    isClearable={false}
                  />
                  <InfoTooltip position='left'>
                    <div>This date will be used to display the posts</div>
                    <div>in the order of original publication.</div>
                  </InfoTooltip>
                </DatePickerRow>
                {
                  !WorksheetStore.showData.isValid && !WorksheetStore.showData.published && WorksheetStore.showData.publishedAt?.isFuture &&
                    <Alert margin='5px 0'>{`If this worksheet is still invalid on ${WorksheetStore.showData.publishedAt.formattedDate}, it will not be published.`}</Alert>
                }
                {
                  !WorksheetStore.showData.isValid && !WorksheetStore.showData.published && WorksheetStore.showData.publishedAt?.isPast &&
                    <Alert margin='5px 0'>This worksheet hasn't been published because it's invalid.</Alert>
                }
              </fieldset>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <fieldset>
                <legend>Youtube Episode</legend>
                <table>
                  <tbody>
                    <tr>
                      <td width="130px">
                        <TableText>
                          Thumbnail
                        </TableText>
                      </td>
                      <td>
                        <InPlaceImageUpload
                          model="images"
                          image={WorksheetStore.showData.image}
                          margin="0"
                          onChange={this.getWorksheet}
                          querystring={{
                            imageable_id: WorksheetStore.showData.id,
                            imageable_type: "Worksheet"
                          }}
                          updateCallback={this.afterUpdate}
                        />
                        {
                          WorksheetStore.showData.youtubeUrlIsValid && isBlank(WorksheetStore.showData.image) && isPresent(WorksheetStore.showData.youtubeThumbnailUrl) &&
                            <ImageWrapper>
                              <i
                                className="fa fa-times"
                                onClick={this.hideYoutubeThumbnail}
                              />
                              <InfoTooltip><div>This image is automatically retrieved from Youtube.</div><div>You can upload another file if you prefer.</div></InfoTooltip>
                              <img alt='' src={WorksheetStore.showData.youtubeThumbnailUrl} />
                            </ImageWrapper>
                        }
                      </td>
                    </tr>
                    <tr>
                      <td width="130px">
                        <TableText>
                          Youtube URL
                          {
                            !WorksheetStore.showData.youtubeUrlIsValid && isBlank(WorksheetStore.showData.podcastEpisode) &&
                              <InvalidIconWrapper
                                data-tip
                                data-for='youtube-url'
                              >
                                <i className='fa fa-warning' />
                                <ReactTooltip
                                  type="dark"
                                  effect="solid"
                                  id='youtube-url'
                                  class="custom-tooltip"
                                >
                                  The Youtube URL must start with 'https://www.youtube.com/'
                                </ReactTooltip>
                              </InvalidIconWrapper>
                          }
                        </TableText>
                      </td>
                      <td>
                        <InPlaceText
                          show
                          submitOnEnter
                          defaultValue={WorksheetStore.showData.youtubeUrl}
                          model="worksheets"
                          field="youtube_url"
                          id={WorksheetStore.showData.id}
                          afterChange={this.getWorksheet}
                          updateCallback={this.afterUpdate}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <fieldset>
                <legend>Preview Text
                  <InfoTooltip>This will be used as a thumbnail if there is no thumbnail image.</InfoTooltip>
                  {
                    isBlank(WorksheetStore.showData.imageUrl) && isBlank(WorksheetStore.showData.previewText) &&
                    <InvalidIconWrapper
                      data-tip
                      data-for='youtube-url'
                    >
                      <i className='fa fa-warning' />
                      <ReactTooltip
                        type="dark"
                        effect="solid"
                        id='youtube-url'
                        class="custom-tooltip"
                      >
                        A worksheet needs either a thumbnail or a preview text
                      </ReactTooltip>
                    </InvalidIconWrapper>
                  }
                </legend>
                <InPlaceText
                  maxLength={300}
                  defaultValue={WorksheetStore.showData.previewText}
                  show={isBlank(WorksheetStore.showData.imageUrl) && isBlank(WorksheetStore.showData.previewText)}
                  model="worksheets"
                  field="preview_text"
                  id={WorksheetStore.showData.id}
                  inputType='textarea'
                  afterChange={this.getWorksheet}
                />
              </fieldset>
            </div>
          </div>
          <div className='row'>
            <div className={`col-xs-${AppUI.site.showWorksheetComments ? '9' : '12'}`}>
              <fieldset>
                <legend>Podcast Episode</legend>
                <PodcastEpisodeSearch
                  onSubmit={(podcastEpisode) => this.addPodcastEpisode(podcastEpisode)}
                />
                {
                  isPresent(WorksheetStore.showData.podcastEpisode) &&
                    <PodcastEpisode>
                      <Title>
                        <Link
                          to={{name: "creator.podcast_episodes.edit", params: {podcastEpisodeId: WorksheetStore.showData.podcastEpisode.id, groupId: this.props.params.groupId}}}
                        >
                          {WorksheetStore.showData.podcastEpisode.title}
                        </Link>
                      </Title>
                      <div style={{width: '40px'}}>
                        <i
                          className='fa fa-times fa-on-click fa-block right'
                          onClick={this.clearPodcastEpisode}
                        />
                      </div>
                    </PodcastEpisode>
                }
              </fieldset>
            </div>
            {
              AppUI.site.showWorksheetComments &&
                <div className='col-xs-3'>
                  <fieldset>
                    <legend>Post Comments</legend>
                    <Row>
                      {
                        WorksheetStore.showData.hasForumThread &&
                        <InPlaceCheckbox
                          blockClassName
                          id={WorksheetStore.showData.forumThread.id}
                          value={WorksheetStore.showData.forumThread.hidden}
                          field="hidden"
                          model="forum_threads"
                          icon="check"
                          afterChange={this.getWorksheet}
                        >
                          Hide Comments
                        </InPlaceCheckbox>
                      }
                      {
                        WorksheetStore.showData.hasForumThread && WorksheetStore.showData.forumThread.hidden &&
                        <i className='fa fa-warning' />
                      }
                    </Row>
                    {
                      WorksheetStore.hasShowData &&
                      <Row>
                        <Link
                          to={{name: "worksheets.comments.show", params: {worksheetId: WorksheetStore.showData?.id}}}
                          className='underline'
                          style={{fontSize: 12}}
                        >
                          Go to comments
                        </Link>
                      </Row>
                    }
                  </fieldset>
                </div>
            }
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <fieldset>
                <legend>Video or Podcast description</legend>
                { isBlank(WorksheetStore.showData.description) && <i className='fa fa-warning' /> }
                <InPlaceText
                  smallRichText
                  show={isBlank(WorksheetStore.showData.description)}
                  defaultValue={WorksheetStore.showData.description}
                  model="worksheets"
                  field="description"
                  id={WorksheetStore.showData.id}
                  afterChange={this.getWorksheet}
                  maxHeight="250px"
                  scrollable
                  updateCallback={this.afterUpdate}
                />
              </fieldset>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <fieldset>
                <legend>Exercises</legend>
                {
                  (WorksheetStore.showData?.hasNoPublishedVocab && WorksheetStore.showData?.exerciseEntriesCount === 0 && WorksheetStore.showData.downloadLinksCount === 0) &&
                     <Row>
                       <i className='fa fa-warning' />
                       <Text color="#ff5252" fontSize="14px" margin="0 10px">This worksheet has no exercises, no published vocab and no downloads.</Text>
                     </Row>
                }
                <Row>
                  <Link
                    to={{name: 'creator.groups.vocab.edit', params: {worksheetId: this.props.params.worksheetId, groupId: this.props.params.groupId}}}
                  >
                    <button
                      className='gray-button'
                      style={{marginBottom: "10px"}}
                    >
                      <i className='fa fa-angle-right' />
                      {`Edit Vocab (${WorksheetStore.showData.worksheetWordsCount} words)`}
                      {
                        WorksheetStore.showData.hasUnpublishedVocab &&
                          <InvalidIconWrapper
                            data-tip
                            data-for='valid-vocab'
                            margin="0 0 0 10px"
                          >
                            <i className='fa fa-warning' />
                            <ReactTooltip
                              type="dark"
                              effect="solid"
                              id='valid-vocab'
                              class="custom-tooltip"
                            >
                              <div>Did you forget to publish the vocab you added?</div>
                            </ReactTooltip>
                          </InvalidIconWrapper>
                      }
                    </button>
                  </Link>
                  <button
                    className='gray-button'
                    style={{marginBottom: "10px"}}
                    onClick={() => this.editDownloadLinks = true}
                  >
                    <i className='fa fa-angle-right' />
                    Edit Download Links ({WorksheetStore.showData.downloadLinks?.length} Link{WorksheetStore.showData.downloadLinks?.length === 1 ? '' : 's'})
                  </button>
                  <button
                    className='gray-button'
                    style={{marginBottom: "10px"}}
                    onClick={() => this.editTags = true}
                  >
                    <i className='fa fa-angle-right' />
                    Edit Tags ({WorksheetStore.showData.tagsCount} Tag{WorksheetStore.showData.tagsCount === 1 ? '' : 's'})
                  </button>
                  <button
                    className='gray-button'
                    style={{marginBottom: "10px"}}
                    onClick={this.onClickGeneratePdf}
                    disabled={this.pdfGenerating}
                  >
                      {this.pdfGenerating ? 'Generating PDF...' : (
                        <>
                          <i className='fa fa-angle-right' />
                          Generate PDF
                        </>
                      )}
                  </button>
                </Row>
                {
                  this.askForPdfGenerationConfirm && isPresent(this.worksheetDownloadLinkType) && isPresent(this.worksheetDownloadLink) &&
                    <Alert
                      showCloseButton
                      onClose={() => this.askForPdfGenerationConfirm = false}
                    >
                      <div>{`The Download Link \'${this.worksheetDownloadLinkType.title}\' already has a link. Do you want to replace it with the newly generated PDF?`}</div>
                      <div style={{display: 'flex', gap: '20px'}}>
                        <div className='underline' onClick={this.generatePdfLink}>Replace with new generated PDF</div>
                        <a href={this.worksheetDownloadLink.text} target='_blank'>See Current Link</a>
                        <div className='underline' onClick={() => this.askForPdfGenerationConfirm = false}>Cancel</div>
                      </div>
                    </Alert>
                }
                {
                  this.showPdfGeneratedConfirmation &&
                    <Alert
                      showCloseButton
                      background={Theme.green}
                      onClose={() => this.showPdfGeneratedConfirmation = false}
                    >
                      <div>{`The PDF of the worksheet was generated${isPresent(this.worksheetDownloadLinkType) && isPresent(this.worksheetDownloadLink) ? ` and added to \'${this.worksheetDownloadLinkType.title}\'` : ''}.`}</div>
                      <a href={this.worksheetDownloadLink.text}><i className='fa fa-download' /> Download PDF</a>
                    </Alert>
                }
                {
                  this.showSlowPdfGenerationWarning && !this.showPdfGeneratedConfirmation &&
                    <Alert
                      showCloseButton
                      onClose={() => this.showSlowPdfGenerationWarning = false}
                    >
                      The PDF is being generated. In a few minutes, refresh the page and check the download links.
                    </Alert>
                }
                <ExerciseCreate
                  withModal
                  worksheetId={this.props.params.worksheetId}
                  afterCreate={this.getWorksheet}
                />
                <ExerciseIndex
                  showPosition
                  afterUpdate={this.getWorksheet}
                  groupId={this.props.params.groupId}
                  worksheetId={this.props.params.worksheetId}
                  exercises={this.exercises}
                />
              </fieldset>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <fieldset>
                <legend>Grid Section</legend>
                <GridItemSearch
                  onSubmit={this.onAddGridItem}
                />
                {
                  WorksheetStore.hasShowData &&
                    <table style={{marginTop: '10px'}} className="table-wrapper admin-table">
                      <thead>
                        <tr>
                          <th width="50" />
                          <th>Name</th>
                          <th>Posts Count</th>
                          <th width="40"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          WorksheetStore.showData.gridItems.map((item) => {
                            return (
                              <tr key={item.id}>
                                <td>
                                  <Link
                                    to={{ name: 'creator.grid_items.edit', params: { gridItemId: item.id, groupId: this.props.params.groupId } }}
                                    className="on-click"
                                  >
                                    <i className="fa fa-chevron-right fa-block" />
                                  </Link>
                                </td>
                                <td>
                                  <div className="text">
                                    {item.name}
                                  </div>
                                </td>
                                <td>
                                  <div className="text">
                                    {item.worksheetsCount}
                                  </div>
                                </td>
                                <td>
                                  <DeleteButton
                                    onConfirm={() => this.onRemoveGridItem(item.id)}
                                    message="Remove this grid row?"
                                    className="fa-block right"
                                  />
                                </td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                }
              </fieldset>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default WorksheetEdit;
