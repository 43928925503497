import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Navigation from 'components/navigation';
import { last } from 'lodash';
import InPlaceLanguageSelect from 'components/form/in_place_language_select';
import { AppUI, ForumUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import { admin } from '@seedlang/style_mixins';

const Wrapper = styled.div`
  ${admin()}
  margin-top: 20px;
`;

const NavigationRow = styled.div`
  display: flex;
  align-items: center;
`;

const NavigationWrapper = styled.div`
  flex: 1;
`;

const Language = styled.div`
  width: 100px;
`;

@observer
class Translator extends React.Component {

  constructor(props) {
    super(props);
    AppUI.languageStore.getShow({ids: {languageId: props.params.languageId}})
  }

  @autobind updateLanguage(val) {
    AppUI.routeStore.routeToNamed(AppUI.routeStore.routeName, {languageId: val});
    AppUI.authUserStore.update({data: {language_id: val}}, this.afterUpdateUser)
  }

  @autobind afterUpdateUser() {
    AppUI.loadUser(this.afterLoadUser);
  }

  @autobind afterLoadUser(resp) {
    AppUI.setAuthUserStoreShowData(resp);
    if (AppUI.routeStore.routeName === 'translator.forum_threads.show') {
      ForumUI.loadForum(AppUI.user.languageForumId, true);
      ForumUI.forumThreadStore.getIndex({filters: {hidden: false, forum_id: AppUI.user.languageForumId}});
    }
  }

  render () {
    return (
      <Wrapper>
        <div className="builder">
          <NavigationRow>
            <NavigationWrapper>
              {
                AppUI.user?.languageId &&
                  <Navigation
                    currentRoute={last(this.props.routes).name}
                    links={[
                      {
                        link: 'translator.home',
                        title: 'Home',
                        params: {languageId: AppUI.user.languageId},
                      },
                      {
                        link: 'translator.decks_index',
                        title: 'Decks',
                        params: {languageId: AppUI.user.languageId},
                      },
                      {
                        link: 'translator.sentences_index',
                        title: 'Sentences',
                        params: {languageId: AppUI.user.languageId},
                      },
                      {
                        link: 'translator.words',
                        title: 'Words',
                        params: {languageId: AppUI.user.languageId},
                      },
                      {
                        link: 'translator.users',
                        title: 'Translators',
                        params: {languageId: AppUI.user.languageId},
                      },
                    ]}
                  />
              }
            </NavigationWrapper>
            <Language>
              <InPlaceLanguageSelect
                showEdit
                includeBlank
                value={AppUI.user.languageId}
                onChange={this.updateLanguage}
              />
            </Language>
          </NavigationRow>
          {this.props.children}
        </div>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Translator);
