import styled from '@emotion/styled';
import { ScheduledSubscriptionStore } from '@seedlang/stores';
import { isPresent } from '@seedlang/utils';
import Text from 'components/text';
import { observer } from 'mobx-react';
import React from 'react';
import { computed, toJS } from 'mobx';
import OutlineButton from 'components/button/outline_button';
import { Theme } from '@seedlang/constants';
import autobind from 'autobind-decorator';

const Wrapper = styled.div`
  margin-top: 40px;
`;

const ScheduledSubscriptionsTable = styled.table`
  width: 100%;
  th {
    text-align: left;
    background: #505050;
    color: white;
    font-size: 14px;
    padding: 5px;
  }
  td {
    font-size: 16px;
    padding: 5px;
    &:first-of-type {
      width: 85%;
    }
  }
`;

const Plan = styled.div`
  font-weight: bold;
`;

const Date = styled.div`
  font-size: 14px;
`;

const ButtonRow = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 899px) {
    flex-direction: column;
    gap: 5px;
  }
`;

@observer
class SettingsBillingScheduledSubscriptionsSection extends React.Component {

  constructor(props) {
    super(props);
    ScheduledSubscriptionStore.getIndex();
  }

  @computed get scheduledSubscriptions() {
    return ScheduledSubscriptionStore.indexData;
  }

  @autobind async cancelScheduledSubscription() {
    await ScheduledSubscriptionStore.cancel({ids: {scheduledSubscriptionId: this.scheduledSubscriptions[0].id}})
    ScheduledSubscriptionStore.getIndex();
  }

  render() {
    console.log('scheduledSubscriptions', toJS(this.scheduledSubscriptions));

    if (!isPresent(this.scheduledSubscriptions)) {
      return null;
    }

    return (
      <Wrapper>
        <Text
          heading="3"
          margin="0 0 20px 0"
        >
          Scheduled Subscriptions
        </Text>
        <ScheduledSubscriptionsTable>
          <thead>
            <tr>
              <th>Plan</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {this.scheduledSubscriptions.map(subscription => (
              <tr key={subscription.id}>
                <td>
                  <Plan>{subscription.membership.title}</Plan>
                  <Date>
                    {subscription.currentPeriodStart.formattedDateWithYear}{" - "}
                    {subscription.currentPeriodEnd.formattedDateWithYear}
                  </Date>
                </td>
                <td>
                  {subscription.amountFormatted}
                </td>
              </tr>
            ))}
          </tbody>
        </ScheduledSubscriptionsTable>
        <ButtonRow>
           {
            <div>
              <OutlineButton
                margin="10px 0 0 0"
                selectedBackground={Theme.red}
                onClick={this.cancelScheduledSubscription}
              >
                <i className='fa fa-times'
                  style={{ marginRight: 4 }}
                />
                Cancel Scheduled Subscription
              </OutlineButton>
            </div>
          }
        </ButtonRow>
      </Wrapper>
    );

  }
};

export default SettingsBillingScheduledSubscriptionsSection;
