import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import InPlaceText from 'components/form/in_place_text';
import { DeckStore, PromotionStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import UserSearch from 'components/user_search';
import { Link } from 'react-router';
import InPlaceSelect from 'components/form/in_place_select';
import { sortBy } from 'lodash';
import { isPresent } from '@seedlang/utils';
import Copy from 'components/copy';

@observer
class PromotionEdit extends React.Component {

  @computed get decks() {
    return sortBy(DeckStore.indexData.filter(item => isPresent(item.concept) && isPresent(item.concept.name)), (item) => item.concept.name);
  }

  @computed get rootUrl() {
    return window.location.hostname === "localhost" ? "http://localhost:3000" : "https://www.seedlang.com";
  }

  componentDidMount() {
    this.loadPromotion();
    DeckStore.getIndex({limit: 999, filters: {open_access: true, deck_type: 'concept'}});
  }

  componentWillUnmount() {
    PromotionStore.clearShowData();
  }

  @autobind onUpdateUser(user) {
    PromotionStore.update({data: {userId: user ? user.id : null}, ids: {promotionId: this.props.params.promotionId}}, this.loadPromotion)
  }

  @autobind loadPromotion() {
    PromotionStore.getShow({ ids: {promotionId: this.props.params.promotionId }}, this.afterGetShow);
  }

  @autobind afterGetShow(resp) {
    PromotionStore.setShowData(resp);
  }

  render() {
    return (
      <div className='promotion-edit'>
        {
          PromotionStore.hasShowData &&
            <div>
              <div className="row">
                <div className="col-xs-4">
                  <fieldset>
                    <legend>Name</legend>
                    <InPlaceText
                      defaultValue={PromotionStore.showData.name}
                      model="promotions"
                      field="name"
                      id={PromotionStore.showData.id}
                    />
                  </fieldset>
                </div>
                <div className="col-xs-4">
                  <fieldset>
                    <legend>User</legend>
                    {
                      !PromotionStore.showData.user &&
                        <UserSearch
                          onSubmit={this.onUpdateUser}
                          placeholder="Search for User"
                          displayField="name"
                        />
                    }
                    {
                      PromotionStore.showData.user &&
                        <div>
                          <Link
                            to={{ name: 'builder.users.edit', params: { userId: PromotionStore.showData.user.id } }}
                          >
                            {PromotionStore.showData.user.name}
                          </Link>
                          <i
                            className='fa fa-times fa-on-click'
                            style={{margin: "3px 0 0 3px"}}
                            onClick={() => this.onUpdateUser()}
                          />
                        </div>
                    }
                  </fieldset>
                </div>
                <div className="col-xs-4">
                  <fieldset>
                    <legend>Deck</legend>
                    <InPlaceSelect
                      id={PromotionStore.showData.id}
                      field="deck_id"
                      model="promotions"
                      value={PromotionStore.showData.deck && PromotionStore.showData.deck.id}
                      includeBlank
                      options={this.decks.map(item => [item.id, item.concept ? item.concept.name : ''])}
                      afterChange={this.loadPromotion}
                    />
                  </fieldset>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-4">
                  <fieldset>
                    <legend>Slug</legend>
                    <InPlaceText
                      id={PromotionStore.showData.id}
                      field="slug"
                      model="promotions"
                      defaultValue={PromotionStore.showData.slug}
                      afterChange={this.loadPromotion}
                    />
                  </fieldset>
                </div>
                <div className="col-xs-4">
                  <fieldset>
                    <legend>Discount</legend>
                    <InPlaceText
                      id={PromotionStore.showData.id}
                      field="discount"
                      model="promotions"
                      defaultValue={PromotionStore.showData.discount}
                    />
                  </fieldset>
                </div>
                <div className="col-xs-4">
                  <fieldset>
                    <legend># Signups</legend>
                    {PromotionStore.showData.signedUpWithCount}
                  </fieldset>
                </div>
              </div>
              {
                isPresent(PromotionStore.showData.slug) &&
                  <div className='row'>
                    <div
                      className='col-xs-12'
                      style={{
                        marginTop: 20,
                      }}
                    >
                      <Copy
                        className='outline-button blue'
                        text={`${this.rootUrl}?pr=${PromotionStore.showData.slug}`}
                      >
                        Click to Copy Promotion Link
                      </Copy>
                    </div>
                  </div>
              }
            </div>
        }
      </div>
    );
  }
}

export default PromotionEdit;
