import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { CustomTabStore } from '@seedlang/stores';
import { computed } from 'mobx';
import autobind from 'autobind-decorator';
import { ExerciseUI } from "@seedlang/state";
import { Link } from "react-router";
import NoAccessButton from "components/worksheet/no_access_button";

const Wrapper = styled.div`

`;

const Image = styled.div`
  img {
    max-width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const TextContent = styled.div`
  padding: 0 20px 20px 20px;
`;

const AdminLink = styled.div`
  margin-top: 5px;
  font-size: 11px;
  text-decoration: underline;
  text-align: center;
  padding: 5px;
`;

const Content = styled.div`
  padding: 10px 0 0 0;
  background: #FFF;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  min-height: 180px;
  li {
    margin-left: 20px;
    list-style-type: disc;
  }
  a {
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  p {
    margin: 10px 0!important;
  }
`;


@observer
class CustomTabShow extends React.Component {

  componentDidMount() {
    this.getCustomTab();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.routeParams.customTabId !== prevProps.routeParams.customTabId) {
      this.getCustomTab();
    }
  }

  componentWillUnmount() {
    CustomTabStore.clearShowData();
  }

  @computed get customTab() {
    return CustomTabStore.showData;
  }

  @autobind getCustomTab() {
    CustomTabStore.getShow({ ids: { customTabId: this.props.params.customTabId }});
  }

  render() {
    return (
      <Wrapper>
        <Content>
          {
            this.customTab.blockedUntilMigrated &&
              <NoAccessButton migrate/>
          }
          {
            !this.customTab.blockedUntilMigrated && this.customTab.content &&
              <TextContent
                dangerouslySetInnerHTML={{ __html: this.customTab.content || "" }}
              />
          }
          {
            !this.customTab.blockedUntilMigrated && this.customTab.imageLinkUrl &&
            <a
              href={this.customTab.imageLinkUrl}
              target='_blank'
              rel="noopener noreferrer"
            >
              {
                this.customTab.image &&
                  <Image>
                    <img src={this.customTab.image.url} alt=""/>
                  </Image>
              }
            </a>
          }
        </Content>
        {
          ExerciseUI.user.anyAdmin && ExerciseUI.worksheet && ExerciseUI.worksheet.groupId  &&
          <AdminLink>
            <Link
              to={{name: 'creator.groups.custom_tabs.edit', params: {groupId: ExerciseUI.worksheet.groupId, customTabId: this.props.params.customTabId}}}
            >
              Admin Link (Custom Tab)
            </Link>
          </AdminLink>
        }
      </Wrapper>
    );
  }
}

export default CustomTabShow;
