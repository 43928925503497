import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { AppUI, ExerciseUI, VocabUI } from '@seedlang/state';
import Text from 'components/text';
import { Link } from 'react-router';
import autobind from 'autobind-decorator';
import { Constants, Languages, Theme } from '@seedlang/constants';
import Paginator from "components/paginator";
import Spinner from "components/spinner";
import { autorun, computed, observable } from "mobx";
import { isBlank, isPresent } from "@seedlang/utils";
import Filter from "components/filter";
import { WorksheetVocabWrapper } from '@seedlang/hoc';
import SortSelector from "components/sort_selector";
import Checkbox from "components/checkbox";
import AddToReviews from "components/vocab/add_to_reviews";
import ReactTooltip from 'react-tooltip';
import NoAccessButton from "components/worksheet/no_access_button";
import ExpandableTextDisplay from "components/expandable_text_display";

const Wrapper = styled.div`

`;

const VocabWrapper = styled.div`
  background: white;
  padding: 20px;
  border-radius: ${props => props.borderRadius};
  margin-bottom: 10px;
`;

const Table = styled.div`
  -webkit-mask-image: ${props => props.hasAccess ? null : "linear-gradient(to bottom,black,60%,transparent)"};
`;

const HeaderRow = styled.div`
  background: #636363;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  height: 30px;
`;

const HeaderCell = styled.div`
  display: flex;
  align-items: center;
  width: ${(props) => props.width};
  flex: ${(props) => props.expand ? 1 : null};
  padding: 0 10px;
  border-right: 1px solid white;
  height: 30px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 5px;
  .register {
    color: ${Theme.gray};
    font-size: 14px;
    font-style: italic;
  }
  .source {
    font-size: 14px;
  }
`;

const Cell = styled.div`
  display: flex;
  align-items: ${props => props.alignItems || 'center'};
  justify-content: ${(props) => props.justifyContent};
  width: ${(props) => props.width};
  flex: ${(props) => props.expand ? 1 : null};
  flex-direction: ${(props) => props.flexDirection};
  padding: ${(props) => props.padding || '0 5px'};
  .selection-button {
    width: 100%;
  }
  .icon-wrapper {
    cursor: pointer;
  }
  flex-wrap: wrap;
  column-gap: 14px;
`;

const Word = styled.div`
  .text {
    border-bottom: ${props => props.isWordStructure ? null : '2px solid #b5b5b5'};
    display: inline-block;
  }
`;

const AdminLink = styled.div`
  margin-top: 5px;
  font-size: 11px;
  text-decoration: underline;
  text-align: center;
  padding: 5px;
`;

const VocabHeader = styled.div`
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 800;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

const VocabFooter = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: ${props => props.justifyContent};
`;

const FilterRow = styled.div`
  display: flex;
`;

const DownloadLink = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  color: #353535;
  margin-bottom: ${(props) => props.marginBottom};
  .fa {
    margin-left: 5px;
  }
`;

@observer
class WorksheetVocab extends React.Component {
  @observable worksheetWordsAreLoaded = false;
  @observable showFooterSpinner = false;

  @autobind wordTargetText(item) {
    if (isPresent(item.wordStructure) && isPresent(item.wordStructure.text)) {
      if (AppUI.layout.isMobile && isPresent(item.wordStructure.shortText)) {
        if (!AppUI.site.showWordPages) {
          return item.wordStructure.shortText;
        }
        return item.wordStructure.shortText.split(' ').map(token => token === item.word.targetText ? `<span style="border-bottom: 2px solid #b5b5b5;">${token}</span>` : token).join(' ');
      } else {
        if (!AppUI.site.showWordPages) {
          return item.wordStructure.text;
        }
          return item.wordStructure.text.split(' ').map(token => item.word.targetText.includes(token) ? `<span style="border-bottom: 2px solid #b5b5b5;">${token}</span>` : token).join(' ');
      }
    }
    if (AppUI.site.hideDefiniteArticle) {
      return item.word.targetTextWithGender;
    } else {
      return item.word.targetTextWithDefiniteArticle;
    }
  }

  @autobind sourceText(item) {
    if (isPresent(item.wordStructure) && isPresent(item.wordStructure.sourceText)) {
      return item.wordStructure.sourceText;
    }
    return item.sourceList;
  }

  @computed get worksheetWordsWorksheetId() {
    if (!ExerciseUI.worksheetWordStore.hasIndexData) return ;
    const idArray = ExerciseUI.worksheetWordStore.indexData.map(item => item.worksheetId)
    return idArray.length > 0 && idArray[0];
  }

  constructor (props) {
    super();
    ExerciseUI.reviewDeckStore.getIndex();
    autorun(() => {
      if (ExerciseUI.hasWorksheet && !this.worksheetWordsAreLoaded && this.worksheetWordsWorksheetId !== ExerciseUI.worksheetId) {
        props.getWorksheetWords(props.params.worksheetId, {clearIndex: true});
        this.worksheetWordsAreLoaded = true;
      }
      if (ExerciseUI.hasWorksheet && isBlank(VocabUI.worksheetId)) {
        VocabUI.worksheetId = ExerciseUI.worksheetId;
      }
      if (AppUI.defaultReviewDeckId && !VocabUI.defaultReviewDeckId) {
        VocabUI.setDefaultReviewDeckId(AppUI.defaultReviewDeckIdForLanguageId(ExerciseUI.languageId));
      }
      if (ExerciseUI.hasWorksheet && isBlank(ExerciseUI.groupId)) {
        ExerciseUI.set('groupId', ExerciseUI.worksheet.groupId);
      }
    });
  }

  componentWillUnmount () {
    VocabUI.worksheetId = null;
    VocabUI.selectedWords = [];
    VocabUI.allToggleSelected  = false;
    VocabUI.updateCardType();
  }

  @autobind toggleAllWorksheetVocab() {
    ExerciseUI.toggleShowAllWorksheetVocab();
    this.showFooterSpinner = true;
    this.props.getWorksheetWords(this.props.params.worksheetId, {clearIndex: false, callback: this.afterShowAllWorksheetVocab});
  }

  @autobind afterShowAllWorksheetVocab(resp) {
    ExerciseUI.worksheetWordStore.setIndexData(resp);
    this.showFooterSpinner = false;
  }

  @computed get worksheetHasAlternateText() {
    if (ExerciseUI.worksheetWordStore.hasIndexData) {
      return ExerciseUI.filteredWorksheetWords.some(item => isPresent(item.word?.alternateTargetText));
    }
  }


  @computed get vocabIsBlocked() {
    return !ExerciseUI.worksheet.userHasAccess || AppUI.vocabBlockedUntilMigrated;
  }

  @computed get reviewsAreEnabled() {
    return ExerciseUI.siteIsDefault || ExerciseUI.site.language?.hasLessons;
  }

  @computed get compactView() {
    let colCount = 2;
    if (this.reviewsAreEnabled) { colCount += 1; }
    if (AppUI.site.showLevelColumn) { colCount += 1; }
    if (AppUI.site.showAlternateTargetText && this.worksheetHasAlternateText) { colCount += 1; }
    return AppUI.layout.isMobile && colCount >= 3;
  }

  render() {
    return (
      <Wrapper>
        {
          ExerciseUI.hasWorksheet && ExerciseUI.worksheet.publishWords &&
          <VocabWrapper
            borderRadius={this.props.borderRadius}
          >
            <FilterRow>
              {
                isPresent(this.props.filterSettings) && isPresent(ExerciseUI.userGroup) &&
                  <Filter
                    filterName="Vocab Filters"
                    noFiltersSelectedMessage={this.props.noFiltersSelectedMessage}
                    filterSettings={this.props.filterSettings}
                    onSetFilter={this.props.onSetFilter}
                    // searchQuery={this.props.searchQuery}
                    hasActiveFilter={this.props.hasActiveFilter}
                    // onToggleFiltersExpanded={this.props.onToggleFiltersExpanded}
                    setShowFilterOptions={this.props.setShowFilterOptions}
                    showFilterOptions={this.props.showFilterOptions}
                    filteredItemsCount={ExerciseUI.worksheetWordStore.totalCount}
                    filteredItemsType="Word"
                    marginBottom="10px"
                    width={isPresent(this.props.sortOptions) ? "80%" : "100%"}
                  />
              }
              {
                isPresent(this.props.sortOptions) && isPresent(ExerciseUI.userGroup) &&
                  <SortSelector
                    margin={isPresent(this.props.filterSettings) ? "10px 0 10px 10px" : "10px 0 10px 0"}
                    width={isPresent(this.props.filterSettings) ? "20%" : null}
                    onToggleSort={this.props.onToggleSort}
                    sortOrder={this.props.sortOrder}
                    sortOptions={this.props.sortOptions}
                    selectedSortLabel={this.props.selectedSortLabel}
                    onSetSort={this.props.onSetSort}
                  />
              }
            </FilterRow>
            <AddToReviews
              reviewDeckOptions={ExerciseUI.reviewDeckOptions}
              deckId={this.props.deckId}
              onSetDeckId={this.props.onSetDeckId}
              cardTypeOptions={this.props.cardTypeOptions}
              cardTypeId={this.props.cardTypeId}
              onSetCardTypeId={this.props.onSetCardTypeId}
              onCreateReviews={this.props.onCreateReviews}
              deckButtonDisabled={this.props.deckButtonDisabled}
              selectedWordsExcludedFromGenderTrainer={this.props.selectedWordsExcludedFromGenderTrainer}
              selectedWordsExcludedFromPluralTrainer={this.props.selectedWordsExcludedFromPluralTrainer}
              selectedWordsExcludedFromConjugationTrainer={this.props.selectedWordsExcludedFromConjugationTrainer}
              showReviewsCreatedMessage={this.props.createReviewsFinished || this.props.createReviewsIsSlow}
              selectedWordIds={this.props.selectedWordIds}
              confirmationMessage={`${this.props.createReviewsIsSlow ? 'Your reviews are being created' : 'Your reviews were successfully created'}${ExerciseUI.siteIsDefault ? '' : ' on Seedlang'}. <a href='https://seedlang.com/reviews/home' target='_blank'>Go to your reviews</a>`}
            />
            {
              VocabUI.showSpinner &&
                <Spinner
                  background={AppUI.site.accentColor || '#868686'}
                />
            }
            {
              ExerciseUI.worksheetWordStore.hasIndexData &&
              <Table
                hasAccess={!this.vocabIsBlocked}
              >
                <HeaderRow>
                  {
                    this.reviewsAreEnabled &&
                      <HeaderCell
                        width="40px"
                      >
                        <Checkbox
                          background="#d0d0d0"
                          selectedBackground="#232323"
                          onClick={this.props.onToggleAddAllToDeck}
                          value={this.props.allToggleSelected}
                        />
                      </HeaderCell>
                  }
                  <HeaderCell
                    expand={!ExerciseUI.languageId?.startsWith('EN')}
                    width={ExerciseUI.languageId?.startsWith('EN') ? AppUI.layout.isMobile ? "100px" : "250px" : null}
                  >
                    {
                      !this.compactView &&
                        <Text
                          color="white"
                          fontSize="12px"
                          bold
                        >
                          { isPresent(ExerciseUI.languageId) ? ExerciseUI.languageId?.startsWith('EN') ? 'English' : Languages[ExerciseUI.languageId] : 'Target Language' }
                        </Text>
                    }
                    {
                      this.compactView &&
                        <Text
                          color="white"
                          fontSize="12px"
                          bold
                        >
                          Word
                        </Text>
                    }
                  </HeaderCell>
                  {
                    AppUI.site.showAlternateTargetText && this.worksheetHasAlternateText &&
                      <HeaderCell
                        expand
                      >
                        <Text
                          color="white"
                          fontSize="12px"
                          bold
                        >
                          {isPresent(AppUI.site.alternateTargetTextName) ? AppUI.site.alternateTargetTextName : 'Romanized spelling'}
                        </Text>
                      </HeaderCell>
                  }
                  {
                    !this.compactView &&
                      <HeaderCell
                        expand
                      >
                        <Text
                          color="white"
                          fontSize="12px"
                          bold
                        >
                          {ExerciseUI.languageId?.startsWith('EN') ? 'Definition' : 'English'}
                        </Text>
                      </HeaderCell>
                  }
                  {
                    AppUI.site.showLevelColumn &&
                      <HeaderCell
                        width="60px"
                      >
                        <Text
                          color="white"
                          fontSize="12px"
                          bold
                        >
                          Level
                        </Text>
                      </HeaderCell>
                  }
                </HeaderRow>
                {
                  // also in worksheet_exports/show.haml
                  ExerciseUI.filteredWorksheetWords.slice(0, !this.vocabIsBlocked ? ExerciseUI.filteredWorksheetWords.length : 5).map(item => {
                    return (
                      <Row
                        key={item.id}
                      >
                        {
                          this.reviewsAreEnabled &&
                            <Cell
                              width="40px"
                              padding="0 10px"
                              data-tip
                              data-for={`incompatible-${item.id}`}
                            >
                              <Checkbox
                                width="22px"
                                onClick={() => this.props.onToggleAddWordToDeck(item.word)}
                                value={this.props.selectedWordIds.indexOf(item.word?.id) !== -1}
                                showLock={!this.props.selectedCardTypeIsCompatible(item.word)}
                              />
                              {
                                !this.props.selectedCardTypeIsCompatible(item.word) &&
                                  <ReactTooltip
                                    place="right"
                                    type="dark"
                                    effect="solid"
                                    id={`incompatible-${item.id}`}
                                    class="custom-tooltip"
                                  >
                                    <div>This word is not compatible</div>
                                    <div>{`with ${Constants.REVIEW_CARD_TYPES.find(item => item.id === this.props.cardTypeId).name} cards.`}</div>
                                  </ReactTooltip>
                              }
                            </Cell>
                        }
                        <Cell
                          expand={!ExerciseUI.languageId?.startsWith('EN')}
                          width={ExerciseUI.languageId?.startsWith('EN') ? AppUI.layout.isMobile ? "100px" : "250px" : null}
                          flexDirection={this.compactView ? 'column' : 'row'}
                          alignItems={this.compactView ? 'left' : null}
                        >
                          {
                            AppUI.site.showWordPages &&
                              <Word
                                isWordStructure={isPresent(item.wordStructure) && isPresent(item.wordStructure.text)}
                              >
                                <Link
                                  to={{name: 'worksheets.vocab.word',
                                    params: {
                                      groupId: this.props.params.groupId,
                                      worksheetId: this.props.params.worksheetId,
                                      wordId: item.word?.id
                                    }
                                  }}
                                >
                                  <Text
                                    fontSize={item.word?.rightToLeftLanguage ? "20px" : "16px"}
                                  >
                                    <div
                                      dangerouslySetInnerHTML={{ __html: this.wordTargetText(item) }}
                                    />
                                  </Text>
                                </Link>
                              </Word>
                          }
                          {
                            !AppUI.site.showWordPages &&
                              <Text
                                fontSize={item.word?.rightToLeftLanguage ? "20px" : "16px"}
                              >
                                <div
                                  dangerouslySetInnerHTML={{ __html: this.wordTargetText(item) }}
                                />
                              </Text>
                          }
                          {
                            item.word?.register &&
                              <div
                                className="register"
                                fontSize="14px"
                              >
                                {item.word?.register}
                              </div>
                          }
                          {
                            this.compactView &&
                              <div
                                className="source"
                              >
                                {
                                  !ExerciseUI.languageId?.startsWith('EN') &&
                                  <div
                                    dangerouslySetInnerHTML={{__html: this.sourceText(item)}}
                                  />
                                }
                                {ExerciseUI.languageId?.startsWith('EN') && item.word.definition}
                              </div>
                          }
                        </Cell>
                        {
                          AppUI.site.showAlternateTargetText && this.worksheetHasAlternateText &&
                          <Cell
                            expand
                          >
                            <Text
                              fontSize="16px"
                            >
                              {item.word?.alternateTargetText}
                            </Text>
                          </Cell>
                        }
                        {
                          !this.compactView &&
                            <Cell
                              expand
                            >
                              <Text
                                fontSize="16px"
                              >
                                {
                                  !ExerciseUI.languageId?.startsWith('EN') &&
                                  <div
                                    dangerouslySetInnerHTML={{__html: this.sourceText(item)}}
                                  />
                                }
                                {ExerciseUI.languageId?.startsWith('EN') && item.word.definition}
                              </Text>
                            </Cell>
                        }
                        {
                          AppUI.site.showLevelColumn &&
                            <Cell
                              width="60px"
                              justifyContent="center"
                            >
                              <Text
                                fontSize="14px"
                                color="#353535"
                              >
                                {item.word?.level?.abbreviation}
                              </Text>
                            </Cell>
                        }
                      </Row>
                    )
                  })
                }
              </Table>
            }
            {
              ExerciseUI.hasWorksheet && this.vocabIsBlocked &&
                <NoAccessButton
                  migrate={AppUI.vocabBlockedUntilMigrated}
                />
            }
            {
              !this.vocabIsBlocked &&
                <VocabFooter
                  justifyContent={this.showFooterSpinner ? 'center' : ExerciseUI.user.showAllWorksheetVocab ? 'flex-end' : 'space-between'}
                >
                  {
                    this.showFooterSpinner &&
                    <Spinner
                      background={AppUI.site.accentColor || '#868686'}
                    />
                  }
                  {
                    !this.showFooterSpinner && ExerciseUI.worksheet.worksheetWordsCount > 50 && ExerciseUI.worksheetWordStore.hasIndexData && !ExerciseUI.user.showAllWorksheetVocab &&
                    <Paginator
                      onClickScrollToTop
                      totalCount={ExerciseUI.worksheetWordStore.totalCount}
                      store={ExerciseUI.worksheetWordStore}
                      ids={{worksheetId: this.props.params.worksheetId}}
                      onPagination={this.props.onPagination}
                    />
                  }
                  {
                    !this.showFooterSpinner && ExerciseUI.worksheet.worksheetWordsCount > 50 && ExerciseUI.worksheetWordStore.hasIndexData && !ExerciseUI.user.showAllWorksheetVocab &&
                    <Link
                      onClick={this.toggleAllWorksheetVocab}
                      className="underline"
                      style={{fontSize: 12}}
                    >
                      {`Show all ${ExerciseUI.worksheetWordStore.totalCount} words`}
                    </Link>
                  }
                  {
                    !this.showFooterSpinner && ExerciseUI.worksheet.worksheetWordsCount > 50 && ExerciseUI.worksheetWordStore.hasIndexData && ExerciseUI.user.showAllWorksheetVocab &&
                    <Link
                      onClick={this.toggleAllWorksheetVocab}
                      className="underline"
                      style={{fontSize: 12, marginTop: '10px'}}
                    >
                      {`Show 50 words per page`}
                    </Link>
                  }
                </VocabFooter>
            }
            {
              (ExerciseUI.worksheetWordStore.hasIndexData || ExerciseUI.worksheetWordStore.totalCount === 0) && isPresent(this.props.filters) &&
                <Text italic center fontSize="12px" margin="10px 0 0 0">{`Displaying ${ExerciseUI.worksheetWordStore.totalCount} of ${ExerciseUI.worksheet.worksheetWordsCount} words with these filters.`}</Text>
            }
          </VocabWrapper>
        }
        {
          ExerciseUI.worksheet.publishUnprocessedVocab && isPresent(ExerciseUI.worksheet.unprocessedVocab) && !this.vocabIsBlocked &&
            <VocabWrapper
              borderTopLeftRadius={isBlank(ExerciseUI.filteredWorksheetWords) || !ExerciseUI.worksheet.publishWords ? 0 : "10px"}
              borderRadius={this.props.standaloneSectionBorderRadius}
            >
              <div dangerouslySetInnerHTML={{ __html: ExerciseUI.worksheet.unprocessedVocab }} />
            </VocabWrapper>
        }
        {
          this.vocabIsBlocked && ExerciseUI.worksheet.publishUnprocessedVocab && isPresent(ExerciseUI.worksheet.unprocessedVocab) && (isBlank(ExerciseUI.filteredWorksheetWords) || !ExerciseUI.worksheet.publishWords) &&
            <VocabWrapper
              borderTopLeftRadius="0"
              borderRadius="10px"
            >
              <ExpandableTextDisplay
                hideReadMore
                htmlText={ExerciseUI.worksheet.unprocessedVocab}
              />
              <NoAccessButton
                migrate={AppUI.vocabBlockedUntilMigrated}
              />
            </VocabWrapper>
        }
        {
          ExerciseUI.user.anyAdmin && ExerciseUI.worksheet && ExerciseUI.worksheet.groupId &&
            <AdminLink>
              <Link
                to={{name: 'creator.groups.vocab.edit', params: {groupId: ExerciseUI.worksheet.groupId, worksheetId: ExerciseUI.worksheet.id}}}
              >
                Admin Link
              </Link>
            </AdminLink>
        }
        </Wrapper>
      );
    }
  }

  export default WorksheetVocabWrapper(WorksheetVocab);
