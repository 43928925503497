import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import { MembershipGroupStore, SubscriptionStore, UserStore, GroupStore, UserGroupStore, PatreonUserStore, MembershipTypeStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceText from 'components/form/in_place_text';
import InPlaceSelect from 'components/form/in_place_select';
import Button from 'components/button/button';
import DeleteButton from 'components/button/delete_button';
import { isPresent, isBlank } from '@seedlang/utils';
import { Constants } from '@seedlang/constants';
import SubscriptionCreate from 'pages/builder/subscriptions/subscription_create';
import Cookies from 'js-cookie';
import { RssFeedStore } from '@seedlang/stores';
import { DevPaymentMigrationStore } from '@seedlang/stores';
import Text from 'components/text';
import InfoTooltip from "components/info_tooltip";
import CampaignMessageIndex from "pages/builder/campaign_messages/campaign_message_index";
import InPlaceDatePicker from 'components/form/in_place_date_picker';
import ContactMessageIndex from "pages/builder/contact_messages/contact_message_index";

const Wrapper = styled.div`

`;

const AddGroup = styled.div`
  margin-top: 10px;
`;

const Note = styled.div`
  background: white;
  margin-top: 10px;
  padding: 10px;
  font-size: 14px;
`;

const IconWrapper = styled.span`
  .fa-file-text {
    color: #333;
    cursor: pointer;
    margin-right: 4px;
  }
`;

@observer
class UsersEdit extends React.Component {
  @observable groupId = "f74a4102-d65b-448c-b261-60b6be2c7eca";
  @observable spinnerForRssFeedId = null;

  @computed get patreonEmailWithoutUser() {
    return isPresent(UserStore.showData.patreonUserEmail) && isBlank(UserStore.showData.patreonUser);
  }

  @computed get noGroup() {
    return isBlank(UserStore.showData.userGroups);
  }

  @computed get group() {
    return GroupStore.hasIndexData ? GroupStore.indexData.filter(item => item.id === this.groupId)[0] : null;
  }

  @computed get hasGroupButNotPermanentOrSynched() {
    return isPresent(UserStore.showData.userGroups) && !UserStore.showData.userGroups[0].permanent && isBlank(UserStore.showData.patreonUser);
  }

  @computed get hasAccess() {
    return this.group && (UserStore.showData.permanentPatreonMember || (UserStore.showData.activePatreonMember && (this.group.allowAllMemberships || this.hasMembershipWithAccess || isBlank(UserStore.showData.patreonUser.rewardTitle))));;
  }

  @computed get hasMembershipWithAccess() {
    if (!GroupStore.hasIndexData || !UserStore.hasShowData || !this.group.allowedMemberships) { return null }
    const allowedMemberships = this.group.allowedMemberships?.split(",");
    return isPresent(allowedMemberships) && UserStore.showData.patreonUser && allowedMemberships.indexOf(UserStore.showData.patreonUser.rewardTitle) !== -1;
  }

  @computed get filterMembershipTypes() {
    const siteId = Cookies.get('site_id');

    return MembershipTypeStore.indexData.filter((item) => item.siteId === siteId);
  }

  constructor(props) {
    super(props);
    this.loadUser();
    this.loadSubscriptions();
    this.loadRssFeeds();
    GroupStore.getIndex();
    if (!MembershipTypeStore.hasIndexData) {
      this.getMembershipTypes();
    }
    if (!MembershipGroupStore.hasIndexData) {
      MembershipGroupStore.getIndex();
    }
  }

  componentWillUnmount() {
    SubscriptionStore.clearIndexData();
  }

  @autobind loadUser() {
    UserStore.getShow({ids: {expand_user: true, userId: this.props.params.userId}});
  }

  @autobind addToGroup() {
    const groupId = AppUI.siteIsDefault ? this.groupId : AppUI.user.site.groupId;

    UserGroupStore.create({data: {userId: this.props.params.userId, groupId: groupId}}, this.loadUser);
  }

  @autobind onDeleteUserGroup(userGroupId) {
    UserGroupStore.destroy({ids: {userGroupId: userGroupId}}, this.loadUser);
  }

  @autobind onRemovePatreonUser() {
    PatreonUserStore.update({data: {has_seedlang_user: false}, ids: {patreonUserId: UserStore.showData.patreonUser.id}});
    UserStore.update({ids: {userId: this.props.params.userId}, data: {patreon_user_email: null}}, this.loadUser);
  }

  @autobind getMembershipTypes() {
    MembershipTypeStore.getIndex();
  }

  @autobind loadSubscriptions() {
    SubscriptionStore.getIndex({queryStrings: {user_id: this.props.params.userId}});
  }

  @autobind loadRssFeeds() {
    RssFeedStore.getIndex({ queryStrings: { user_id: this.props.params.userId } }, (resp) => {
      RssFeedStore.setIndexData(resp);
      this.spinnerForRssFeedId = null;
    });
  }

  @autobind onUpdateFeed(rssFeed) {
    this.spinnerForRssFeedId = rssFeed.id;
    RssFeedStore.updateFeed({ ids: { rssFeedId: rssFeed.id, user_id: this.props.params.userId } }, this.loadRssFeeds);
  }

  @autobind onRegenerateUrl(rssFeed) {
    this.spinnerForRssFeedId = rssFeed.id;
    RssFeedStore.regenerateUrl({ ids: { rssFeedId: rssFeed.id, user_id: this.props.params.userId } }, this.loadRssFeeds);
  }

  @autobind onDevInitializePaymentMigration() {
    DevPaymentMigrationStore.create({data: {siteId: AppUI.site.id, userId: this.props.params.userId}}, this.loadUser);
  }

  @autobind displayBoolean(value) {
    if (isBlank(value)) {
      return 'Not Set';
    }
    return value ? '✅' : '❌';
  }

  render() {
    return (
      <Wrapper>
        <div className='breadcrumbs' style={{marginBottom: 20}}>
          <Link
            to={{name: 'creator.groups.users.index', params: {groupId: this.props.params.groupId}}}
          >
            All Users
          </Link>
        </div>
        {
          UserStore.hasShowData &&
            <div>
              <div className='row'>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Email</legend>
                    {UserStore.showData.email}
                  </fieldset>
                </div>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Name</legend>
                    {UserStore.showData.name}
                  </fieldset>
                </div>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Role</legend>
                    <InPlaceSelect
                      includeBlank
                      id={UserStore.showData.id}
                      value={UserStore.showData.role}
                      model="users"
                      field="role"
                      options={Constants.USER_ROLES}
                      afterChange={this.loadUser}
                    />
                  </fieldset>
                </div>
                {
                  AppUI.siteIsDefault &&
                    <div className='col-xs-4'>
                      <fieldset>
                        <legend>Settings</legend>
                        <InPlaceCheckbox
                          model="users"
                          field="permanent_membership"
                          id={UserStore.showData.id}
                          value={UserStore.showData.permanentMembership}
                          icon="check"
                          afterChange={this.loadUser}
                        >
                          Permanent Seedlang Pro Membership
                        </InPlaceCheckbox>
                      </fieldset>
                    </div>
                }
              </div>
              {
                AppUI.siteIsDefault &&
                <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Patreon User</legend>
                    {
                      !UserStore.showData.patreonUser &&
                        <InPlaceText
                          defaultValue={UserStore.showData.patreonUserEmail}
                          model="users"
                          field="patreon_user_email"
                          id={UserStore.showData.id}
                          placeholder="Enter Email"
                          afterChange={this.loadUser}
                        />
                    }
                    {
                      UserStore.showData.patreonUser &&
                        <table
                          className="table-wrapper"
                        >
                          <thead>
                            <tr>
                              <th>Email</th>
                              <th>Membership Title</th>
                              <th>Active Patron</th>
                              <th width="50" />
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Link
                                  to={{name: "creator.groups.patreon_users.edit", params: {groupId: this.props.params.groupId, patreonUserId: UserStore.showData.patreonUser.id}}}
                                >
                                  {UserStore.showData.patreonUser.email}
                                </Link>
                              </td>
                              <td>
                                <InPlaceText
                                  defaultValue={UserStore.showData.patreonUser.rewardTitle}
                                  model="patreon_users"
                                  field="reward_title"
                                  id={UserStore.showData.patreonUser.id}
                                  afterChange={this.loadUser}
                                />
                              </td>
                              <td>
                                <InPlaceCheckbox
                                  icon="check"
                                  model="patreon_users"
                                  id={UserStore.showData.patreonUser.id}
                                  afterChange={this.loadPatreonUser}
                                  field="is_patreon_active"
                                  value={UserStore.showData.patreonUser.isPatreonActive}
                                />
                              </td>
                              <td>
                                <DeleteButton
                                  message="Remove Association?"
                                  className="fa-block right"
                                  onConfirm={() => this.onRemovePatreonUser()}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                    }
                    {
                      this.patreonEmailWithoutUser &&
                        <Note>
                          <i className='fa fa-warning' style={{marginRight: 5}} />
                          A Patreon user email has been submitted, but no patron user was found. If a Patreon user is later created with this email address, we will synch the Seedlang and Patron accounts.
                        </Note>
                    }
                  </fieldset>
                </div>
              </div>
              }
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>User Groups</legend>
                    <table
                      className="table-wrapper"
                    >
                      <thead>
                        <tr>
                          <th>Name</th>
                          {
                            !AppUI.siteIsDefault &&
                              <th>Membership Type</th>
                          }
                          <th>Free</th>
                          <th>Free Until</th>
                          <th>Agreements</th>
                          <th width="50" />
                        </tr>
                      </thead>
                      <tbody>
                        {
                          UserStore.showData.userGroups.filter(item => item.group?.siteId === AppUI.site.id).map(item => {
                            return (
                              <tr key={item.id}>
                                <td>
                                  {item.name}
                                </td>
                                {
                                  !AppUI.siteIsDefault &&
                                    <td>
                                      <InPlaceSelect
                                        includeBlank
                                        id={item.id}
                                        model="user_groups"
                                        field="membership_type_id"
                                        options={this.filterMembershipTypes.map(item => [item.id, item.name])}
                                        value={item.membershipTypeId}
                                        afterChange={this.loadUser}
                                      />
                                    </td>
                                }
                                <td>
                                  <InPlaceCheckbox
                                    model="user_groups"
                                    id={item.id}
                                    field="permanent"
                                    value={item.permanent}
                                    icon="check"
                                    afterChange={this.loadUser}
                                  />
                                  {
                                    !AppUI.siteIsDefault && item.permanent && isBlank(item.membershipTypeId) &&
                                      <InfoTooltip color='red' icon='warning'>A free member needs to have membership type</InfoTooltip>
                                  }
                                </td>
                                <td>
                                  <InPlaceDatePicker
                                    model="user_groups"
                                    id={item.id}
                                    field="permanent_until"
                                    value={item.permanentUntil}
                                    afterChange={this.loadUser}
                                  />
                                </td>
                                <td>
                                  <div>Email: {this.displayBoolean(item.userAgreedToEmail)}</div>
                                  <div>Terms: {this.displayBoolean(item.userAgreedToTerms)}</div>
                                </td>
                                <td>
                                  <DeleteButton
                                    message="Remove from Group?"
                                    className="fa-block right"
                                    onConfirm={() => this.onDeleteUserGroup(item.id)}
                                  />
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                    {
                      UserStore.showData.userGroups.length === 0 &&
                        <AddGroup>
                          <Button
                            onClick={this.addToGroup}
                          >
                            Add to Group
                          </Button>
                        </AddGroup>
                    }
                  </fieldset>
                </div>
              </div>
            </div>
        }
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Subscriptions</legend>
              <SubscriptionCreate
                userId={UserStore.showData.id}
                afterCreate={this.loadSubscriptions}
              />
              <table
                className="table-wrapper"
              >
                <thead>
                  <tr>
                    <th />
                    <th>Created At</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Cancelled At</th>
                    <th>Amount</th>
                    <th>Cncl</th>
                    <th>Ref</th>
                    <th>Pnd</th>
                    <th>#Pay</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    SubscriptionStore.indexData.map(item => {
                      return (
                        <tr>
                          <td>
                            <Link
                              to={AppUI.siteIsDefault ? { name: 'builder.subscriptions.edit', params: { subscriptionId: item.id } } : { name: 'creator.groups.subscriptions.edit', params: { groupId: this.props.params.groupId, subscriptionId: item.id } }}
                              className="on-click"
                            >
                              <i className="fa fa-chevron-right fa-block" />
                            </Link>
                          </td>
                          <td>
                            {item.createdAt.formatted}
                          </td>
                          <td>
                            <div className="text">
                              {item.currentPeriodStart.formattedDateWithYear}
                            </div>
                          </td>
                          <td>
                            <div className="text">
                              {item.currentPeriodEnd.formattedDateWithYear}
                            </div>
                          </td>
                          <td>
                            <div>{item.cancelledAt && item.cancelledAt.formattedDateWithYear}</div>
                            <div>{item.cancellationMessage && <IconWrapper onClick={() => this.cancellationMessage = item.cancellationMessage}><i className='fa fa-file-text' /></IconWrapper>}{item.cancellationReason}</div>
                          </td>
                          <td>
                            <div>{item.amountFormatted}</div>
                            <div>{item.paymentMethod}</div>
                          </td>
                          <td>
                            <div className="text">
                              <InPlaceCheckbox
                                model="subscriptions"
                                field="cancel_at_period_end"
                                id={item.id}
                                value={item.cancelAtPeriodEnd}
                                icon="check"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="text">
                              <InPlaceCheckbox
                                model="subscriptions"
                                field="refunded"
                                id={item.id}
                                value={item.refunded}
                                icon="check"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="text">
                              <InPlaceCheckbox
                                model="subscriptions"
                                field="pending"
                                id={item.id}
                                value={item.pending}
                                icon="check"
                              />
                            </div>
                          </td>
                          <td>
                            {item.paymentsCount}
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </fieldset>
          </div>
        </div>
        {
          UserStore.showData.paymentMigration &&
            <div className='row'>
              <div className='col-xs-12'>
                <fieldset>
                  <legend>Payment Migration</legend>
                  <div>
                    <Text style={{ fontSize: 14 }}>
                      {UserStore.showData.paymentMigration.friendlyStatus}
                    </Text>
                  </div>
                </fieldset>
              </div>
            </div>
        }
        {
          AppUI.user.developer &&
            <fieldset>
              <legend>Payment Migration (for developers only)</legend>
              {
                UserStore.showData.paymentMigration?.id &&
                  <div className='row'>
                    <div className='col-xs-8'>
                      <InPlaceSelect
                        includeBlank
                        id={UserStore.showData.paymentMigration.id}
                        model="dev_payment_migrations"
                        field="status"
                        options={["setting_up","stripe_payment_setup_started","waiting_for_patreon_cancellation","waiting_for_patreon_expiry","waiting_for_patreon_refund","waiting_for_patreon_refund_timeout","completed"]}
                        value={UserStore.showData.paymentMigration.status}
                        afterChange={this.loadUser}
                      />
                    </div>
                    <div className='col-xs-4'>
                      <InPlaceSelect
                        includeBlank
                        id={UserStore.showData.paymentMigration.id}
                        model="dev_payment_migrations"
                        field="patreon_pledge_cadence"
                        options={[[12, "Yearly"], [1, "Monthly"]]}
                        value={UserStore.showData.paymentMigration.patreonPledgeCadence}
                        afterChange={this.loadUser}
                      />
                    </div>
                  </div>
                }
                {
                  !UserStore.showData.paymentMigration?.id &&
                    <button className='button-primary'
                      onClick={this.onDevInitializePaymentMigration}
                    >
                      Initialize Payment Migration
                    </button>
                }
            </fieldset>
        }
        {
          isPresent(UserStore.showData.currentUserGroup) &&
            <fieldset>
              <legend>Membership Group</legend>
              <InPlaceSelect
                includeBlank
                id={UserStore.showData.currentUserGroup.id}
                model="user_groups"
                field="membership_group_id"
                options={MembershipGroupStore.indexData.map(item => [item.id, item.name])}
                value={UserStore.showData.currentUserGroup.membershipGroupId}
                afterChange={UserStore.reloadShow.bind(UserStore)}
              />
            </fieldset>
        }
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Contact Messages</legend>
              <Text fontSize="14px">
                The emails <i>sent</i> by this user.
              </Text>
              <ContactMessageIndex
                userId={this.props.params.userId}
                limit={5}
                whiteLabel
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Email Notification Settings</legend>
              <div style={{display: 'flex', gap: '20px'}}>
                <InPlaceCheckbox
                  model="user_groups"
                  field="block_all_mailings"
                  id={UserStore.showData.currentUserGroup?.id}
                  value={UserStore.showData.currentUserGroup?.blockAllMailings}
                  icon="check"
                  afterChange={UserStore.reloadShow.bind(UserStore)}
                >
                  Block All Emails
                </InPlaceCheckbox>
                <Text fontSize="14px" style={{display: 'flex', gap: '5px'}}><label>Blocked email types:</label> {UserStore.showData.blockMailingByType}</Text>
              </div>
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Campaign Messages</legend>
              <Text fontSize="14px">
                The emails <i>received</i> by this user.
              </Text>
              <CampaignMessageIndex
                whiteLabel
                groupId={this.props.params.groupId}
                userId={this.props.params.userId}
                siteId={AppUI.siteId}
                limit={5}
              />
            </fieldset>
          </div>
        </div>
        {
          !AppUI.siteIsDefault && (
            <div className='row'>
              <div className='col-xs-12'>
                <fieldset>
                  <legend>RSS Feeds</legend>
                  <table
                    className="table-wrapper"
                  >
                    <thead>
                      <tr>
                        <th>Podcast</th>
                        <th>Private RSS feed URL</th>
                        <th></th>
                        <th>Last Updated</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        RssFeedStore.indexData.map(item => {
                          return (
                            <tr key={item.id}>
                              <td>
                                {item.podcast.title}
                              </td>
                              <td style={{ width: '27em'}}>
                                {item.url}
                              </td>
                              <td>
                                <DeleteButton
                                  message="A new RSS URL will be generated and the existing one will stop working. Do you want to continue?"
                                  faIcon="fa-recycle"
                                  onConfirm={this.onRegenerateUrl.bind(this, item)}
                                />
                              </td>
                              <td style={{ width: '8em' }}>
                                {item.updatedAt.formatted}
                              </td>
                              <td style={{ width: '8em'}}>
                                <button className="button-primary" onClick={this.onUpdateFeed.bind(this, item)}>
                                  Update Feed
                                </button>
                              </td>
                              <td style={{ width: '6em'}}>
                                {this.spinnerForRssFeedId === item.id && <i title="Please allow 20 seconds for an update.">Updating...</i>}
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </fieldset>
              </div>
            </div>
          )
        }
        {
          this.hasAccess &&
            <Note>
              <span role="img" aria-label="celebration">🎉</span> This user has membership access.
            </Note>
        }
        {
          this.noGroup && AppUI.siteIsDefault &&
            <Note>
              <i className='fa fa-warning' style={{marginRight: 5}} />
              This user will not see the Patron button, because they have not been added to a group
            </Note>
        }
        {
          !this.hasAccess && !this.noGroup && AppUI.siteIsDefault &&
            <Note>
              <div style={{fontWeight: 'bold'}}>
              <i className='fa fa-warning' style={{marginRight: 5}} /> This user does not worksheet access.
              </div>
              <div>
                To have access, they would need to either:
              </div>
              <div>
                * have "Permanent" access checked
              </div>
              <div>
                * be synched to a Patreon User that has a blank Membership or one of these Memberships:
              </div>
              {
                this.group &&
                  <ul>
                    {
                      this.group.allowedMemberships && this.group.allowedMemberships.split(",").map(item => {
                        return (
                          <li
                            key={item}
                            style={{margin: "0 0 0 10px"}}
                          >
                            {item}
                          </li>
                        )
                      })
                    }
                  </ul>
              }
            </Note>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(UsersEdit);
