import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import PodcastEpisodeCreate from 'pages/creator/podcast_episodes/podcast_episode_create';
import { PodcastEpisodeStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import DeleteButton from 'components/button/delete_button';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import InPlaceText from 'components/form/in_place_text';
import ReactTooltip from 'react-tooltip';
import { isPresent, isBlank } from "@seedlang/utils";
import { ExerciseUI } from "@seedlang/state";
import Checkbox from "components/checkbox";
import { observable, toJS } from "mobx";
import { remove } from "lodash";
import Button from 'components/button/button';
import { Theme } from "@seedlang/constants";
import Spinner from "components/spinner";

const Wrapper = styled.div`
  table {
    margin-top: 20px;
  }
`;

@observer
class PodcastEpisodeIndex extends React.Component {

  @observable selectedEpisodeIds = [];
  @observable allEpisodesSelected = false;
  @observable createPostSpinner = false;

  constructor(props) {
    super(props);
    this.getPodcastEpisodes();
  }

  @autobind onDelete(id) {
    PodcastEpisodeStore.destroy({ids: {podcastEpisodeId: id}}, this.getPodcastEpisodes);
  }

  @autobind getPodcastEpisodes() {
    PodcastEpisodeStore.getIndex();
  }

  @autobind onClickWorksheet(podcastEpisode) {
    if (isPresent(podcastEpisode.worksheetId)) {
      ExerciseUI.routeStore.routeToNamed('creator.groups.worksheets.edit', {groupId: this.props.params.groupId, worksheetId: podcastEpisode.worksheetId})
    } else if (isPresent(podcastEpisode)) {
      this.createPostSpinner = podcastEpisode.id;
      ExerciseUI.worksheetStore.create({data: {podcast_episode_id: podcastEpisode.id}}, this.afterCreateWorksheet)
    }
  }

  @autobind afterCreateWorksheet(resp) {
    ExerciseUI.routeStore.routeToNamed('creator.groups.worksheets.edit', {groupId: this.props.params.groupId, worksheetId: resp.id})
  }

  @autobind onSelectEpisode(episode) {
    if (this.selectedEpisodeIds.indexOf(episode.id) !== -1) {
      remove(this.selectedEpisodeIds, (item) => item === episode.id)
    } else {
      this.selectedEpisodeIds.push(episode.id)
    }
  }

  @autobind selectAllEpisodes() {
    if (this.allEpisodesSelected) {
      this.allEpisodesSelected = false;
      this.selectedEpisodeIds = [];
    } else {
      this.allEpisodesSelected = true;
      PodcastEpisodeStore.indexData.forEach(item => {
        if (this.selectedEpisodeIds.indexOf(item.id) === -1) {
          this.selectedEpisodeIds.push(item.id);
        }
      });
    }
  }

  @autobind destroySelectedEpisodes() {
    PodcastEpisodeStore.batchDelete({data: {ids: this.selectedEpisodeIds}}, this.afterDestroySelectedEpisodes);
  }

  @autobind afterDestroySelectedEpisodes() {
    this.selectedEpisodeIds = [];
    this.getPodcastEpisodes();
  }

  render () {
    return (
      <Wrapper>
        <PodcastEpisodeCreate
          afterCreate={this.getPodcastEpisodes}
        />
        <table className="table-wrapper admin-table">
          <thead>
            <tr>
              <th width="20" >
                <Checkbox
                  value={this.allEpisodesSelected}
                  onClick={this.selectAllEpisodes}
                />
              </th>
              <th width="20" />
              <th>Episode #</th>
              <th>Title</th>
              <th>Subtitle</th>
              <th>Podcast</th>
              <th>Post</th>
              <th width="40"></th>
            </tr>
          </thead>
          <tbody>
            {
              PodcastEpisodeStore.indexData.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Checkbox
                        value={this.selectedEpisodeIds.indexOf(item.id) !== -1}
                        onClick={() => this.onSelectEpisode(item)}
                      />
                    </td>
                    <td>
                      <Link
                        to={{ name: 'creator.podcast_episodes.edit', params: { podcastEpisodeId: item.id, groupId: this.props.params.groupId } }}
                        className="on-click"
                      >
                        <i className="fa fa-chevron-right fa-block" />
                      </Link>
                    </td>
                    <td style={{width: "100px"}}>
                      <div className="text">
                        <InPlaceText
                          field="episode_number"
                          defaultValue={item.episodeNumber}
                          model="podcast_episodes"
                          id={item.id}
                        />
                      </div>
                    </td>
                    <td style={{width: "200px"}}>
                      <div className="text">
                        <InPlaceText
                          field="title"
                          defaultValue={item.title}
                          model="podcast_episodes"
                          id={item.id}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        <InPlaceText
                          field="subtitle"
                          defaultValue={item.subtitle}
                          model="podcast_episodes"
                          id={item.id}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {item.podcast?.title}
                      </div>
                    </td>
                    <td>
                      <div
                        style={{display: "flex", justifyContent: 'center', cursor: 'pointer'}}
                        data-tip
                        data-for={`worksheet-${item.id}`}
                        onClick={() => this.onClickWorksheet(item)}
                      >
                        {
                          isPresent(item.worksheetId) && this.createPostSpinner !== item.id &&
                            <i style={{fontSize: 18}} className='fa fa-check-circle' />
                        }
                        {
                          !isPresent(item.worksheetId) && this.createPostSpinner !== item.id &&
                            <i style={{fontSize: 18, color: 'gray'}} className='fa fa-plus-circle' />
                        }
                        {
                          this.createPostSpinner=== item.id &&
                            <Spinner />
                        }
                        <ReactTooltip
                          place="left"
                          type="dark"
                          effect="solid"
                          id={`worksheet-${item.id}`}
                          class="custom-tooltip"
                        >
                          {isPresent(item.worksheetId) ? 'Go to Post' : 'Create Post'}
                        </ReactTooltip>
                      </div>
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={() => this.onDelete(item.id)}
                        message="Delete this grid item?"
                        className="fa-block right"
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={PodcastEpisodeStore}
          onPagination={() => this.allEpisodesSelected = false}
        />
        {
          PodcastEpisodeStore.hasIndexData &&
            <DeleteButton
              confirmationMessageFloatsRight
              top='-12px'
              left='200px'
              right='auto'
              message={`${this.selectedEpisodeIds.length} podcast episodes will be deleted`}
              disabled={isBlank(this.selectedEpisodeIds)}
              onConfirm={this.destroySelectedEpisodes}
            >
              <Button
                background={Theme.red}
                margin='10px 0 0 0'
                disabled={isBlank(this.selectedEpisodeIds)}
              >
                <i className="fa fa-trash"/>
                {`Delete ${this.selectedEpisodeIds.length} episodes`}
              </Button>
            </DeleteButton>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(PodcastEpisodeIndex);
